import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import Breadcrumb from '../../components/Breadcrumb';
import CalendarNavigation from '../../components/CalendarNavigation';
import DropItem from '../../components/commonfield/DropItem';
import PredictionItem from './PredictionItem';
import PredictionCard from './PredictionCard';
import CloseIcon from '../../components/CloseIcon';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import EmptyBox from '../../components/EmptyBox';
import Loaders from '../../components/Loader';

// const parseDate = (isoDate) => {
//   const date = new Date(isoDate);
//   return date.toLocaleTimeString('en-US', {
//     hour: '2-digit',
//     minute: '2-digit',
//     hour12: true,
//   });
// };
const parseDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Set to false for 24-hour format
    });
  };
  

const formatDateForApi = (date) => {
  const formattedDate = new Date(date);
  return formattedDate.toISOString().split('T')[0];
};

const formatDateForDisplay = (isoDate) => {
  const date = new Date(isoDate);
  return date.toLocaleDateString('en-US', {
    weekday: 'long', // Full name of the day (e.g., "Monday")
    day: '2-digit', // Two-digit day (e.g., "12")
    month: 'short', // Abbreviated month name (e.g., "Nov")
    year: 'numeric', // Four-digit year (e.g., "2024")
  });
};

const impactDisplayMapping = {
    'high': 'High',
    'medium': 'Medium',
    'low': 'Low',
    'no_impact': 'No Impact'
  };
  


export default function PageCalendar() {
  const [isCurrency, setIsCurrency] = useState('Select Currency');
  const [isImpact, setIsImpact] = useState('Select Impact');
  const [newsData, setNewsData] = useState([]);
  const [isPrediction, setIsPrediction] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const impact = ['Select Impact', 'High', 'Medium', 'Low', 'No Impact'];

  const currency = ['Select Currency', 'AUD', 'USD', 'CAD', 'EUR', 'CHF', 'CNY', 'GBP', 'JPY', 'NZD'];
  const [selectedNewsItem, setSelectedNewsItem] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const impactMapping = {
    'Select Impact': 'Select Impact',
    'High': 'high',
    'Medium': 'medium',
    'Low': 'small',
    'No Impact': 'no_impact'
  };

  // Create the reverse mapping if needed for display
  const displayImpactMapping = Object.keys(impactMapping).reduce((acc, key) => {
    acc[impactMapping[key]] = key;
    return acc;
  }, {});

  const handlePredictionOpen = (newsItem) => {
    setSelectedDate(newsItem.date); // Optional: Set the date to the clicked item's date.
    setSelectedNewsItem(newsItem);
    setIsPrediction(true);
  };
  

  const handleCurrencyChange = (option) => {
    setIsCurrency(option);
  };

  const handleImpactChange = (option) => {
    const dbValue = impactMapping[option] || ''; // Get the stored value for the selected display value
    setIsImpact(dbValue);
  };


  

  const handlePredictionClose = () => {
    setIsPrediction(false);
  };

  // Function to go to the previous day
  const goToPreviousDate = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    setSelectedDate(formatDateForApi(newDate));
  };

  // Function to go to the next day
  const goToNextDate = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    setSelectedDate(formatDateForApi(newDate));
  };

  // Memoize today's date so it doesn't trigger re-renders unnecessarily
  const today = useMemo(() => new Date().toISOString().split('T')[0], []);

  // Fetch news data whenever `selectedDate` changes
  const { apiCall } = useApi();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        
        const formattedDate = formatDateForApi(selectedDate);
        const params = {
          day: [formattedDate],
          currency_id: isCurrency !== 'Select Currency' ? isCurrency : undefined, // Pass currency if not default
          impact: isImpact !== 'Select Impact' ? [isImpact] : undefined, // Pass impact if not default
        };

        console.log('Request Params:', params);

        const response = await apiCall(`${API_URL}get-news`, params);

        if (response && response.data && response.data.success == 0 && Array.isArray(response.data.data) && response.data.data.length > 0) {
          setNewsData(response.data.data);
        } else {
          console.warn('No valid news data available for this date or success is 0');
          setNewsData([]);
        }
        setDataLoaded(true)
      } catch (error) {
        console.error('Error fetching news:', error);
        setNewsData([]);
      }
    };

    fetchNews();
  }, [selectedDate, isCurrency, isImpact]); // Include `isCurrency` and `isImpact` in dependency array


  return (
    <>
    {!dataLoaded && <Loaders/>}
      <Breadcrumb className="" breadcrumbIcon="CalendarSvg" breadcrumbHeading="Calendar" />
      <div className="container-lg cl-custome3">
        <div className="row row-gap-4">
          <div className="col-12">
            <div className="common-box p-0">
              <div className="prediction-filter-bx">
                <CalendarNavigation
                  date={formatDateForDisplay(selectedDate)} // Use the formatting function here
                  prev={goToPreviousDate}
                  next={goToNextDate}
                />
                <div className="pfb-drop-bx">
                  <div className="pfb-drop-item">
                    <div className="sgr-outer-label">Currency</div>
                    <DropItem
                      options={currency}
                      selectedOption={isCurrency}
                      onChange={handleCurrencyChange}
                    />
                  </div>
                  <div className="pfb-drop-item">
                    <div className="sgr-outer-label">Impact</div>
                    <DropItem
                      options={impact}
                      selectedOption={displayImpactMapping[isImpact] || isImpact}
                      onChange={handleImpactChange}
                    />
                  </div>
                </div>
              </div>
              {newsData.length > 0 ? (
                <div className="table-responsive tr-prediction">
                  <table className="custom-table off-tr-bg ct-prediction">
                    <thead>
                      <tr>
                        <th className='th-time'></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {newsData.map((newsItem) => (
                        <PredictionItem
                          key={newsItem.id}
                          time={parseDate(newsItem.date)}
                          child={
                            <>
                            {newsItem.records.map((newsRecordItem) => (
                              <PredictionCard
                              onClick={() => handlePredictionOpen(newsRecordItem)}
                              impact={impactDisplayMapping[newsRecordItem.impact] || 'Unknown'} 
                                statusClass={`status-${newsRecordItem.impact === 'no_impact' ? 'blue2' :
                                  newsRecordItem.impact === 'high' ? 'red2' :
                                    newsRecordItem.impact === 'low' ? 'yellow2' :
                                      newsRecordItem.impact === 'medium' ? 'green2' :
                                        'default'  // Fallback if no match found
                                  }`}
                                currency={newsRecordItem.currency_name}
                                event={newsRecordItem.event_type}
                                actual={newsRecordItem.actual || 'N/A'}
                                forecast={newsRecordItem.forecast}
                                previous={newsRecordItem.previous}
                              />
                            ))}
                            </>
                          }
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <EmptyBox className="empty-div-bx edb-border-top" eh="News not found" esh="Try adjusting filters to get results." />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="custom-modal-header">
          <div className="cmh-lable">Saturday, Dec 14, 2024</div>
          <div className="cmh-sub-lable">Time: 7 AM</div>
          
          <CloseIcon onClick={handlePredictionClose} />
        </div> */}

        <Modal className="zoom custom-content" centered show={isPrediction} onHide={handlePredictionClose}>
  <div className="custom-modal-header">
  {selectedNewsItem ? (
      <>
        <div className="cmh-lable">
          {formatDateForDisplay(selectedNewsItem.date)}
        </div>
        <div className="cmh-sub-lable">
          Time: {parseDate(selectedNewsItem.date)}
        </div>
      </>
    ) : (
      <div>No details available</div>
    )}
          
          <CloseIcon onClick={handlePredictionClose} />
  </div>
  <div className="custom-modal-body">
    {selectedNewsItem ? (
      <PredictionCard
        className="pci-full mb-4"
        impact={impactDisplayMapping[selectedNewsItem.impact] || 'Unknown'}
        statusClass={`status-${
          selectedNewsItem.impact === 'no_impact' ? 'blue2' :
          selectedNewsItem.impact === 'high' ? 'red2' :
          selectedNewsItem.impact === 'small' ? 'yellow' :
          selectedNewsItem.impact === 'medium' ? 'green' :
          'default'
        }`}
        currency={selectedNewsItem.currency_name}
        event={selectedNewsItem.event_type}
        actual={selectedNewsItem.actual || 'N/A'}
        forecast={selectedNewsItem.forecast}
        previous={selectedNewsItem.previous}
        text={selectedNewsItem.description}
      />
    ) : (
      <div>No news available for this item.</div>
    )}
  </div>
</Modal>

    </>
  );
}
