import React from 'react'

export default function CloseIcon({className="", onClick}) {
  return (
    <span className={`close-icon ${className}`} onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
        </svg>
    </span>
  )
}
