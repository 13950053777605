import React, { useState, useEffect } from 'react';
import Icons from '../icons';

export default function DropItem({ className="" , options = [], selectedOption, onChange }) {
    const [isDropdownActive, setIsDropdownActive] = useState(false);

    const handleMouseLeave = () => {
        setIsDropdownActive(false);
    };

    const handleToggle = () => {
        setIsDropdownActive(!isDropdownActive);
    };

    const handleOptionClick = (option) => {
        onChange(option);
        setIsDropdownActive(false);
    };

    useEffect(() => {
        const body = document.body;

        // Add or remove the "overflowhidden" class based on either isActive or isSearchbar state
        if (isDropdownActive) {
        body.classList.add('isDropActive');
        } else {
        body.classList.remove('isDropActive');
        }

        // Cleanup the class when the component unmounts
        return () => {
        body.classList.remove('isDropActive');
        };

    }, [isDropdownActive]);

    return (
        <div className={`common-drop-item ${className} ${isDropdownActive ? 'active' : ''}`} onMouseLeave={handleMouseLeave}>
            <div className="cdi-current" onClick={handleToggle}>
                {selectedOption}
                <span className="cc-icon">
                    <Icons.DownSvg />
                </span>
            </div>
            <div className="common-drop-bx">
                <div className="common-drop-inner">
                    {options.map((option) => (
                        <div key={option} className={`cdi-item ${option === selectedOption ? 'cdi-seleted' : ''}`} onClick={() => handleOptionClick(option)}>
                            {option}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
