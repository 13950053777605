import React from 'react'
import { Link } from 'react-router-dom';

// Components File
import Socialicon2 from '../../components/Socialicon2';

export default function HelpCenter() {
  return (
    <div className="common-box cb-help-center">
        <div className="chc-left">
          <div className="common-box-heading cbh-16small mb-2">Help Center?</div>
          <div className="common-box-text">Get In Touch With Us Either Through Our Socials Or Visit The Help Center On Our Website</div>
          <Link to="https://fundedfirm.com/contact" target="/blank" className="common-btn cb-eg3-fill cwf-am-hover mt-3"><span>Help Center</span></Link>
        </div>
        <div className="chc-right">
          <div className="common-box-heading mb-2">Help Center?</div>
          <Socialicon2 className='sib-style-2' />
        </div>
    </div>
  )
}
