import React from 'react';

export default function ChallengeRequestCard({ col, name, step, limit, isRealAccount = false, status = 'pending' }) {
  // Determine class and text based on conditions
  let className = "ccb-other-ac";
  let realAccountMessage = `Your request for challenge ${name} ${step} for account size $${limit} is under review`;

  if (isRealAccount) {
    if (status == "pending") {
      className = "ccb-green-ac";
      realAccountMessage = `Your real account request for ${step} account ${name} is under review`;
    } else if (status == "rejected") {
      className = "ccb-red-ac";
      realAccountMessage = `Your real account request for ${step} account ${name} is rejected`;
    } else if (status == "approved") {
      className = "ccb-green-ac";
      realAccountMessage = `Your real account request for ${step} account ${name} is approved`;
    }
  }


  let accountMessage = `Your request for challenge ${name} ${step} for account size $${limit} is under review`;

  if (!isRealAccount) {
    if (status == "pending") {
      className = "ccb-other-ac";
      accountMessage = `Your request for challenge ${name} ${step} for account size $${limit} is under review`;
    } else if (status == "rejected") {
      className = "ccb-red-ac";
      accountMessage = `Your request for challenge ${name} ${step} for account size $${limit} is rejected`;
    } else if (status == "approved") {
      className = "ccb-green-ac";
      accountMessage = `Your request for challenge ${name} ${step} for account size $${limit} is approved`;
    }
  }

  return (
    <div className={col}>
      <div className={`ccb-request-info ${className}`}>
          {isRealAccount ? realAccountMessage : accountMessage}
      </div>
    </div>
  );
}
