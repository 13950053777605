import React from 'react';
import Stack from '@mui/material/Stack';
import { useYScale, useDrawingArea } from '@mui/x-charts/hooks';
import { LineChart, areaElementClasses } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
// import { axisClasses } from '@mui/x-charts/ChartsAxis';

function TransparentColorGradientLine({ id }) {
    const { top, height, bottom } = useDrawingArea();
    const svgHeight = top + bottom + height;

    const scale = useYScale();

    return (
        <defs>
            <linearGradient
                id={id}
                x1="0"
                x2="0"
                y1="0"
                y2={`${svgHeight}px`}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={scale(4000) / svgHeight} stopColor="#33b17b" stopOpacity={1} />
                <stop offset={scale(0) / svgHeight} stopColor="#33b17b" stopOpacity={0.1} />
                <stop offset={scale(0) / svgHeight} stopColor="#F63D68" stopOpacity={0.1} />
                <stop offset={scale(-4000) / svgHeight} stopColor="#F63D68" stopOpacity={1} />
            </linearGradient>
        </defs>
    );
}

function TransparentColorGradientBar({ id }) {
    return (
        <defs>
            {/* Gradient for bar elements */}
            <linearGradient id={`${id}`} x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" stopColor="#33b17b" stopOpacity={1} />
                <stop offset="100%" stopColor="#33b17b" stopOpacity={0.1} />
            </linearGradient>
        </defs>
    );
}

export default function AccountChart2({profitChartData,filterValue,startDate,endDate, chartType}) {
    console.log('profitChartData',profitChartData);
    
    const valueFormatter = (value) => {
        const absValue = Math.abs(value);
        const formattedValue = absValue >= 1000 ? `${(absValue / 1000).toFixed(3)}k` : absValue.toString();
        return value < 0 ? `-$${formattedValue}` : `$${formattedValue}`;
    };

    const DateDifferenceAndFormat = (start_date, end_date) => {
        // Example start and end dates in the specified format
        const startDateString = start_date;
        const endDateString = end_date;

        // Parse strings into Date objects
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);

        // Calculate the difference in milliseconds
        const diffInMilliseconds = Math.abs(endDate - startDate);

        // Convert milliseconds into other units if needed
        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);

        // Return data or use as needed
        return diffInDays;
    }
    const getYAxisMin = (data) => {
        const minProfit = Math.min(...data.map((item) => item.profit));
        // If the minimum profit is exactly -200, adjust it to a smaller value
        return minProfit === -200 ? -300 : minProfit - 100; // Add a buffer of 10
    };
    const anotherFormatDate = (dateString) => {
        //console.log('startDate',startDate);
        console.log('filterValue',filterValue);
        if (filterValue === 'today' || filterValue === 'week') {
            const date = new Date(dateString);
            // console.log(date);
            const options = {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            };

            return date.toLocaleString('en-US', options);
        } else if (filterValue === 'custom' && DateDifferenceAndFormat(startDate, endDate) <= 7) {
            const date = new Date(dateString);
            // console.log(date);
            const options = {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            };

            return date.toLocaleString('en-US', options);

        } else if (filterValue === 'daily') {
            const date = new Date(dateString);
            // console.log(date);
            const options = {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            };

            return date.toLocaleString('en-US', options);

        } else {
            console.log('fsdfsdfsdfdsf');
            
            const date = new Date(dateString);
            const options = {
                month: 'short'
            };

            return date.toLocaleString('en-US', options);
        }
    };

    const tooltipFormatter = (data) => {
        // if (!data || !data.month || !data.Profit) return [];
        console.log(data);
        return [
            { name: 'Month', value: data.month },
            { name: 'Profit', value: data.Profit },
        ];
    };

    const transformChartData = () => {
        return profitChartData.map(item => ({
            Profit: Number(item.profit),
            month: anotherFormatDate(item.date_formatted)
        }));
    };
    // console.log('tooltipFormatter',tooltipFormatter);
    // console.log('fdsdf',Math.min(...profitChartData.map((item) => item.profit), -10));
    const lineChartSetting = {
        borderRadius: 8,
        height: 305,
        series: [
            {
                dataKey: 'Profit',
                color: "#33b17b",
                showMark: false,
                area: true,
                valueFormatter,
            },
        ],
        xAxis: [
            {
                scaleType: 'point',
                dataKey: 'month',
            },
        ],
        yAxis: [
            { 
                valueFormatter, 
                min: getYAxisMin(profitChartData),
                max: Math.max(...profitChartData.map((item) => item.profit), 100),
            },
        ],
        tooltip: {
            formatter: tooltipFormatter,
        },
        sx: {
            [`& .${areaElementClasses.root}`]: {
                fill: 'url(#transparent-color-gradient)',
            },
        },
        grid: {
            vertical: {
                strokeDasharray: '0',
            },
            horizontal: {
                strokeDasharray: '1',
            },
        },
    };

    const barChartSetting = {
        borderRadius: 8,
        height: 305,
        series: [
            {
                color: '#37b793',
                dataKey: 'Profit',
                valueFormatter,
            },
        ],
        xAxis: [
            {
                scaleType: 'band',
                dataKey: 'month',
            },
        ],
        yAxis: [
            { 
                valueFormatter, 
                min: getYAxisMin(profitChartData),
                max: Math.max(...profitChartData.map((item) => item.profit), 100),
            },
        ],
        grid: {
            vertical: {
                strokeDasharray: '0',
            },
            horizontal: {
                strokeDasharray: '1',
            },
        },
        tooltip: {
            formatter: tooltipFormatter,
        },
        sx: {
            [`& rect`]: {
                fill: 'url(#bar-gradient)',
            },
        },
    };

    // const dataset1 = [
        // { Profit: 190, month: 'Jan' },
        // { Profit: 90, month: 'Feb' },
        // { Profit: 120, month: 'Mar' },
        // { Profit: -157, month: 'Apr' },
        // { Profit: 20, month: 'May' },
        // { Profit: -58, month: 'June' },
        // { Profit: 187, month: 'July' },
        // { Profit: -155, month: 'Aug' },
        // { Profit: -130, month: 'Sept' },
        // { Profit: 120, month: 'Oct' },
        // { Profit: -170, month: 'Nov' },
        // { Profit: 123, month: 'Dec' },
    // ];

    console.log(transformChartData());
    

    return (
        <div className={`Mycharts account-chart ${chartType === 'line' ? "line-chart" : ""}`}>
            <Stack direction="column" width="100%" spacing={1}>
                {chartType === 'line' ? (
                    // dataset={transformChartData()}
                    <LineChart dataset={transformChartData()} {...lineChartSetting}>
                        <TransparentColorGradientLine id="transparent-color-gradient" />
                    </LineChart>
                 ) : (
                    // dataset={transformChartData()}
                    <BarChart dataset={transformChartData()} {...barChartSetting}>
                        <TransparentColorGradientBar id="bar-gradient" />
                    </BarChart>
                )}
            </Stack>
        </div>
    );
}
