import React from 'react'
import { Link } from 'react-router-dom'

import subscription from "../../img/icons/subscription.png"

export default function Subscription() {
  return (
    <div className="col-12">
        <div className="common-box cb-subscription-bx">
          <div className='csb-data'>
            <div className="common-box-heading mb-1">Unlock Your Exclusive Access Today!</div>
            <div className="common-box-text">Don’t miss out! Start now to access premium features and take control of your journey. Join thousands who’ve already upgraded and are enjoying full benefits!</div>
            <Link to="/new-challenge" className="common-btn cb-eg3-fill cwf-am-hover mt-3"><span>Buy Challenge</span></Link>
          </div>
          <img className="csb-img" src={subscription} alt="" />
        </div>
    </div>
  )
}
