import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import FlashMessage from '../../components/FlashMessage';

// React Bootstrap
import { Modal } from 'react-bootstrap';

// Icons & Images Files
import Icons from '../../components/icons';
import * as Img from '../../components/Img';
import mt5 from "../../img/icons/mt5.png"

// Common Field
import Input from '../../components/commonfield/Input'
import SelectInput from '../../components/commonfield/SelectInput'
import CheckInput from '../../components/commonfield/CheckInput'
import FileUpload from '../../components/commonfield/FileUpload';
import Loaders from '../../components/Loader';

// Components File
import PaymentMethodItem from './PaymentMethodItem'
import CloseIcon from '../../components/CloseIcon';


export default function PageChallenge() {
    const { apiCall } = useApi();
    const navigate = useNavigate();
    const { state, dispatch } = useAuth();
    const token = state.token || '';
    const isInitialMount = useRef(true);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
    const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);
    const [requestSubmitError, setRequestSubmitError] = useState(false);
    const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const [couponError, setCouponError] = useState(false);
    const [couponErrorMsg, setCouponErrorMsg] = useState(false);
    const [couponSuccess, setCouponSuccess] = useState(false);
    const [couponSuccessMsg, setCouponSuccessMsg] = useState(false);
    const [appliedCoupon, setAppliedCoupon] = useState({ id: '', code: '', discount: 0 });



    //masters
    const [countries, setCountries] = useState(null);
    const [accountTypes, setAccountTypes] = useState(null);
    const [platforms, setPlatforms] = useState(null);
    const [accountSizes, setAccountSizes] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const [currentUpiIndex, setCurrentUpiIndex] = useState(0);
    const [filteredPaymentMethods, setFilteredPaymentMethods] = useState([]);

    //fields
    const [accountTypeId, setAccountTypeId] = useState(null);
    const [accountSizeId, setAccountSizeId] = useState(null);
    const [selectedAccountSize, setSelectedAccountSize] = useState(null);
    const [selectedAccountType, setSelectedAccountType] = useState(null);
    const [countryId, setCountryId] = useState(null);
    const [platformId, setPlatformId] = useState(null);
    const [notUsCitizen, setNotUsCitizen] = useState(false);
    const [title, setTitle] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [street, setStreet] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [city, setCity] = useState(null);
    const [addressCountry, setAddressCountry] = useState(null);
    const [paymentMethodId, setPaymentMethodId] = useState(null);
    const [amount, setAmount] = useState(null);
    const [screenshot, setScreenshot] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [utrId, setUtrId] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);


    const [convertAmount, setConvertAmount] = useState(0);
    const convertedDiscountedTotal = convertAmount - (appliedCoupon.discount || 0) * convertAmount / (selectedAccountSize?.price || 1);


    const [showCoupon, setShowCoupon] = useState(false);

    const toggleCoupon = () => {
        setShowCoupon(!showCoupon);
    };


    useEffect(() => {
        const fetchData = async () => {
            const loggedInUser = getLoggedInUserData();
            const isUserLoggedIn = !!loggedInUser;

            if (isUserLoggedIn) {
                if (loggedInUser.id && token) {
                    try {
                        const countries = await getCountries();
                        const accountTypes = await getAccountTypes();
                        const platforms = await getPlatforms();
                        const paymentMethods = await getPaymentMethods();
                        setDataLoaded(true);
                        // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
                    } catch (error) {
                        console.error('Failed to fetch wallet group data:', error);
                    }
                }
            }
        };

        if (isInitialMount.current) {
            isInitialMount.current = false;
            fetchData();
        }
    }, [token]);

    const getAccountTypes = async () => {
        // Logic to get user wallet group-wise data
        try {
            const response = await apiCall(API_URL + 'get-account-types', {
                user_id: state.userData.id,
                token: token,
                nopaginate: 1
            });
            if (response.data.success == '0') {
                setAccountTypes(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getPlatforms = async () => {
        // Logic to get user wallet group-wise data
        try {
            const response = await apiCall(API_URL + 'get-platforms', {
                user_id: state.userData.id,
                token: token,
                nopaginate: 1
            });
            if (response.data.success == '0') {
                setPlatforms(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const applyCoupon = async () => {
        try {
            setIsLoading(true);
            setCouponError(false);
            setCouponSuccess(false);

            const response = await apiCall(API_URL + 'validate-promo-code', {
                user_id: state.userData.id,
                code: couponCode,
                token: token,
            });

            console.log('API Response:', response);

            if (response.data.success === 0) {
                const { id, code, discount_in_rupees, discount_in_pr } = response.data.data;

                // Determine which discount to apply
                let discount;
                let discountType;

                if (discount_in_rupees !== null) {
                    discount = discount_in_rupees;
                    discountType = 'rupees';
                } else if (discount_in_pr !== null) {
                    discount = discount_in_pr;
                    discountType = 'percentage';
                }

                setCouponSuccess(true);
                setCouponSuccessMsg(
                    discountType === 'rupees'
                        ? `Coupon applied successfully. You saved $${discount}!`
                        : `Coupon applied successfully. You get ${discount}% discount!`
                );

                setAppliedCoupon({
                    id: id,
                    code: code,
                    discount: discount,
                    discountType: discountType,
                });
            } else {
                const errorMessage = response.data.message || 'Invalid Coupon Code';
                if (errorMessage.includes('Promo code has already been used by you')) {
                    setCouponError(true);
                    setCouponErrorMsg('Promo code has already been used by you.');
                } else {
                    setCouponError(true);
                    setCouponErrorMsg(errorMessage);
                }
            }
        } catch (error) {
            setCouponError(true);
            setCouponErrorMsg('An error occurred while applying the promo code.');
            console.error('Error applying coupon:', error);
        } finally {
            setIsLoading(false);
        }
    };









    const getConvertAmount = async (fAmount) => {
        // Logic to get user wallet group-wise data
        try {
            const response = await apiCall(API_URL + 'get-convert-currency', {
                user_id: state.userData.id,
                token: token,
                amount: fAmount
            });
            if (response.data.success == '0') {
                console.log(response.data.data);
                setConvertAmount(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getCountries = async () => {
        // Logic to get user wallet group-wise data
        try {
            const response = await apiCall(API_URL + 'get-countries', {
                user_id: state.userData.id,
                token: token,
                nopaginate: 1
            });
            if (response.data.success == '0') {
                const countryOptions = response.data.data.map(item => ({
                    value: item.id,
                    name: item.name
                }));
                setCountries(countryOptions);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    // const getPaymentMethods = async () => {
    //     // Logic to get user wallet group-wise data
    //     try {
    //         const response = await apiCall(API_URL + 'get-payment-methods', {
    //             user_id: state.userData.id,
    //             token: token,
    //             nopaginate: 1
    //         });
    //         if (response.data.success == '0') {
    //             setPaymentMethods(response.data.data);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    const getPaymentMethods = async () => {
        try {
            const response = await apiCall(API_URL + 'get-payment-methods', {
                user_id: state.userData.id,
                token: token,
                nopaginate: 1
            });
            if (response.data.success == '0') {
                setPaymentMethods(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Effect to fetch data when component mounts
    useEffect(() => {
        getPaymentMethods();
    }, [state.userData.id, token, API_URL]);

    // Effect to handle UPI cycling
    // useEffect(() => {
    //     if (paymentMethods) {
    //         const upiMethods = paymentMethods.filter(option => option.name.toLowerCase() === 'upi');
    //         const nonUpiMethods = paymentMethods.filter(option => option.name.toLowerCase() !== 'upi');

    //         // Set initial filtered payment methods with one UPI option
    //         setFilteredPaymentMethods([
    //             ...nonUpiMethods,
    //             upiMethods[currentUpiIndex], // Show one UPI option based on index
    //         ]);

    //         // Cycle through UPI options every 30 seconds (30,000 ms)
    //         const intervalId = setInterval(() => {
    //             setCurrentUpiIndex(prevIndex => (prevIndex + 1) % upiMethods.length);
    //         }, 30 * 1000); // 30 seconds

    //         // Clean up the interval on component unmount
    //         return () => clearInterval(intervalId);
    //     }
    // }, [paymentMethods, currentUpiIndex]);
    useEffect(() => {
        if (paymentMethods) {
            const upiMethods = paymentMethods.filter(option => option.name.toLowerCase() === 'upi');
            const nonUpiMethods = paymentMethods.filter(option => option.name.toLowerCase() !== 'upi');

            // Randomly pick a UPI option the first time
            const initialUpiMethod = upiMethods.length > 0 ? upiMethods[Math.floor(Math.random() * upiMethods.length)] : null;

            // Set initial filtered payment methods with one random UPI option
            setFilteredPaymentMethods([
                ...nonUpiMethods,
                initialUpiMethod || null, // Show the random UPI option or null if no UPI methods are found
            ]);

            // Cycle through UPI options every 30 seconds (30,000 ms)
            const intervalId = setInterval(() => {
                setCurrentUpiIndex(prevIndex => (prevIndex + 1) % upiMethods.length);
            }, 5 * 60 * 1000); // 30 seconds

            // Clean up the interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [paymentMethods, currentUpiIndex]);

    useEffect(() => {
        if (accountTypes) {
            setAccountTypeId(accountTypes[0].id)
        }
    }, [accountTypes]);

    useEffect(() => {
        if (accountSizes) {
            setAccountSizeId(accountSizes[0].id)
        }
    }, [accountSizes]);

    useEffect(() => {
        if (platforms) {
            setPlatformId(platforms[0].id)
        }
    }, [platforms]);

    useEffect(() => {
        if (accountTypeId) {
            getAccountSizes();
            setSelectedAccountType(accountTypes.find(method => method.id === accountTypeId))
        }
    }, [accountTypeId]);



    useEffect(() => {
        if (amount) {
            getConvertAmount(amount);
        }
    }, [amount]);

    useEffect(() => {
        if (selectedAccountSize) {
            setAmount(selectedAccountSize.price)
        }
    }, [selectedAccountSize]);

    useEffect(() => {
        if (accountSizeId) {
            setSelectedAccountSize(accountSizes.find(method => method.id === accountSizeId));
        }
    }, [accountSizeId]);

    const getAccountSizes = async () => {
        // Logic to get user wallet group-wise data
        try {
            const response = await apiCall(API_URL + 'get-account-sizes', {
                user_id: state.userData.id,
                token: token,
                nopaginate: 1,
                account_type_id: accountTypeId
            });
            if (response.data.success == '0') {
                setAccountSizes(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    // --- Sign Up Tabbing Js Start
    const [challengeStep, setChallengeStep] = useState('step1');

    const handleStepClick = (stepId) => {
        if (stepId == 'step2') {
            const newErrors = {};
            if (!countryId) {
                newErrors.country = 'Country is required';
                setErrors(newErrors);
            } else {
                setChallengeStep(stepId)

            }
        } else {
            setChallengeStep(stepId);
        }
        window.scrollTo(0, 0);
        setIsAgreeModal(false);
    };
    // --- Sign Up Tabbing Js End

    // --- Sign Up Tabbing Js Start
    const [paymentStep, setPaymentStep] = useState('paymentStep1');

    const handlePaymentStepClick = (stepId) => {
        if (stepId == 'paymentStep1') {
            setSelectedPaymentMethod(null);
            setPaymentMethodId(null);
        }
        setPaymentStep(stepId);
    };
    // --- Sign Up Tabbing Js End

    // --- Payment section Tabbing Js Start
    const [paymentTab, setPaymentTab] = useState('QRCode');

    const handlePaymentClick = (PaymentTabId) => {
        setPaymentTab(PaymentTabId);
    };
    // --- Payment section Tabbing Js End

    // ---
    const [isAgreeModal, setIsAgreeModal] = useState(false);

    const handleAgreeModalClose = () => {
        setIsAgreeModal(false);
    };

    const handleAgreeModalShow = () => {
        const valErrors = validateFieldsStep1()
        if (Object.keys(valErrors).length === 0) {
            setIsAgreeModal(true);
        } else {
            setErrors(valErrors);
        }
    }
    // ---

    // ---
    const [infoShow, setInfoShow] = useState(false);
    const handleInfoClose = () => {
        setInfoShow(false);
    };
    const handleInfoShow = () => {
        setInfoShow(true);
    }
    // ---

    const [selectedValue, setSelectedValue] = useState(false);

    const TitleOptions = [
        { value: '', label: 'Title', name: 'Title' },
        { value: 'mr.', label: 'Mr.', name: 'Mr.' },
        { value: 'mrs.', label: 'Mrs.', name: 'Mrs.' },
        { value: 'ms.', label: 'Ms.', name: 'Ms.' },
        { value: 'mx.', label: 'Mx.', name: 'Mx.' },
    ];
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const handleAccountTypeChange = (accountType) => {
        setAccountTypeId(accountType);
    }
    const handleAccountSizeChange = (accountSize) => {
        setAccountSizeId(accountSize);
    }
    const handleCountryChange = (event) => {
        // console.log(event.target.value);
        setCountryId(event.target.value);
        setErrors((prev) => ({ ...prev, country: '' }));
    }
    const handleNotUsCitizenChange = (event) => {
        var notUs = event.target.checked;
        if (notUs) {
            setErrors((prev) => ({ ...prev, not_us_citizen: '' }));
        }
        setNotUsCitizen(notUs)
    }
    const handleTitleChange = (event) => {
        setTitle(event.target.value);
        setErrors((prev) => ({ ...prev, title: '' }));
    }
    const handleFirstNameChange = (name) => {
        setFirstName(name);
        setErrors((prev) => ({ ...prev, first_name: '' }));
    }
    const handleLastNameChange = (name) => {
        setLastName(name);
        setErrors((prev) => ({ ...prev, last_name: '' }));
    }
    const handleStreetChange = (street) => {
        setStreet(street);
        setErrors((prev) => ({ ...prev, street: '' }));
    }
    const handlePostalCodeChange = (code) => {
        setPostalCode(code);
        setErrors((prev) => ({ ...prev, postal_code: '' }));
    }
    const handleCityChange = (city) => {
        setCity(city);
        setErrors((prev) => ({ ...prev, city: '' }));
    }
    const handleAddressCountryChange = (event) => {
        setAddressCountry(event.target.value);
        setErrors((prev) => ({ ...prev, address_country: '' }));
    }
    const handlePaymentMethodIdChange = (payment_method) => {
        console.log(payment_method);
        setPaymentMethodId(payment_method);
    }
    const handleAmountChange = (amount) => {
        setAmount(amount)
        setErrors((prev) => ({ ...prev, amount: '' }));
    }
    const handleTransactionId = (trxId) => {
        setTransactionId(trxId)
        setErrors((prev) => ({ ...prev, transaction_id: '' }));
    }
    const handleUtrId = (utrId) => {
        setUtrId(utrId)
        // setErrors((prev) => ({ ...prev, utr_id: '' }));
        if (utrId.length !== 12) {
            setErrors((prev) => ({ ...prev, utr_id: 'UTRID must be exactly 12 characters.' }));
        } else {
            setErrors((prev) => ({ ...prev, utr_id: '' })); // Clear error if valid
        }
    }
    const handleFileUpload = (files) => {
        if (files && files.length > 0) {
            setScreenshot(files[0]);
            setErrors((prev) => ({ ...prev, screenshot: '' }));
        } else {
            setScreenshot(null);
            setErrors((prev) => ({ ...prev, screenshot: 'Screenshot is required.' }));
        }
        // setScreenshot(files[0]);
        // setErrors((prev) => ({ ...prev, screenshot: '' }));
    }

    useEffect(() => {
        if (paymentMethodId) {
            setSelectedPaymentMethod(paymentMethods.find(method => method.id === paymentMethodId));
        }
    }, [paymentMethodId]);
    useEffect(() => {
        if (selectedPaymentMethod) {
            handlePaymentStepClick('paymentStep2')
            // setSelectedPaymentMethod(null)
        }
    }, [selectedPaymentMethod]);
    // const changePaymentMethod = () => {
    //     // Find the next payment method that contains a valid upi_id
    //     const newPaymentMethod = paymentMethods.find(
    //         method => method.upi_id && method.upi_id !== '' && method.id !== selectedPaymentMethod.id
    //     );

    //     if (newPaymentMethod) {
    //         setSelectedPaymentMethod(newPaymentMethod);
    //     } else {
    //         alert('No other payment methods with a valid UPI ID found.');
    //     }
    // };

    const changePaymentMethod = () => {
        // Filter out payment methods with a valid upi_id
        const validPaymentMethods = paymentMethods.filter(
            method => method.upi_id && method.upi_id !== '' && method.id !== selectedPaymentMethod?.id
        );

        if (validPaymentMethods.length > 0) {
            // Randomly select a payment method from the filtered list
            const randomMethod = validPaymentMethods[Math.floor(Math.random() * validPaymentMethods.length)];

            // Update both selectedPaymentMethod and paymentMethodId
            setSelectedPaymentMethod(randomMethod);
            setPaymentMethodId(randomMethod.id);  // Update the paymentMethodId
        } else {
            // alert('No other payment methods with a valid UPI ID found.');
        }
    };



    const validateFieldsStep1 = () => {
        const newErrors = {};
        if (!title) {
            newErrors.title = 'Title is required';
        }
        if (!firstName) {
            newErrors.first_name = 'First name is required';
        }
        if (!lastName) {
            newErrors.last_name = 'Last name is required';
        }
        if (!street) {
            newErrors.street = 'Street is required';
        }
        if (!city) {
            newErrors.city = 'City is required';
        }
        if (!postalCode) {
            newErrors.postal_code = 'Postal Code is required';
        }
        if (!addressCountry) {
            newErrors.address_country = 'Country is required';
        }


        // if (!email) {
        //   newErrors.email = 'Email is required';
        // } else if(email){
        //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //   if (!emailRegex.test(email)) {
        //     newErrors.email = 'Email is not valid';
        //   } 
        // }
        // if (!agree) {
        //   newErrors.agree = 'Please agree on terms and conditions';
        // } 
        return newErrors;
    };

    const validateFieldsStep2 = () => {
        const newErrors = {};
        if (!amount) {
            newErrors.amount = 'Amount is required';
        }
        if (!utrId && !transactionId) {
            // Both are missing
            newErrors.utr_id = 'UTR id is required';
            newErrors.transaction_id = 'Transaction id is required';
        } else {
            // No error if either utrId or transactionId is provided
            if (utrId) {
                delete newErrors.utr_id; // Clear any existing error for utrId
            }
            if (transactionId) {
                delete newErrors.transaction_id; // Clear any existing error for transactionId
            }
        }



        if (!screenshot) {
            newErrors.screenshot = 'Screenshot is required.';
        } else if (screenshot.size > 5 * 1024 * 1024) {
            newErrors.screenshot = 'Screenshot file size should be less than 5MB.';
        } else if (!['image/jpeg', 'image/png'].includes(screenshot.type)) {
            newErrors.screenshot = 'Screenshot must be a JPEG or PNG image.';
        }
        return newErrors;
    };

    const handleSubmit = async () => {
        const valErrors = validateFieldsStep2();
        if (Object.keys(valErrors).length === 0) {
            // Create a FormData object
            const formData = new FormData();
            formData.append('user_id', state.userData?.id);
            formData.append('token', state?.token);
            formData.append('title', title);
            formData.append('first_name', firstName);
            formData.append('last_name', lastName);
            formData.append('street', street);
            formData.append('city', city);
            formData.append('postal_code', postalCode);
            formData.append('address_country_id', addressCountry);
            formData.append('country_id', countryId);
            formData.append('account_type_id', accountTypeId);
            formData.append('account_size_id', accountSizeId);
            formData.append('not_us_residence', notUsCitizen);
            formData.append('platform_id', platformId);
            formData.append('payment_method_id', paymentMethodId);
            formData.append('amount', amount);
            formData.append('transaction_id', transactionId);
            formData.append('utr_id', utrId);
            formData.append('screenshot', screenshot);
            if (appliedCoupon?.code) {
                formData.append('coupon_id', appliedCoupon.id);
                formData.append('coupon_code', appliedCoupon.code);

                let discountAmount;

                // Calculate discount amount
                if (appliedCoupon.discountType === 'rupees') {
                    discountAmount = appliedCoupon.discount;
                    formData.append('discount_in_rupees', discountAmount);
                    formData.append('discount_in_pr', null); // Ensure percentage discount is not saved
                } else if (appliedCoupon.discountType === 'percentage') {
                    // Calculate the discount amount from percentage
                    discountAmount = (amount * appliedCoupon.discount) / 100;
                    formData.append('discount_in_rupees', discountAmount);
                    formData.append('discount_in_pr', null); // Ensure percentage discount is not saved
                }

                formData.append('discount', discountAmount); // Save the calculated discount amount
            }

            // Append each file to FormData
            // selectedDocumentTypes.forEach((value, index) => {
            //   if(selectedFiles[index].length > 0){
            //     formData.append(`document_type_id[]`, value);
            //   }
            // });
            // selectedFiles.forEach((object, index) => {
            //   object.forEach((file, idx) => {
            //     formData.append(`documents[]`, file);
            //   });
            // });

            try {
                // Send FormData using Axios
                setIsLoading(true);
                setRequestSubmitSuccsess(false)
                setRequestSubmitError(false)
                apiCall(API_URL + 'send-challange-request', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.data.success == '0') {
                            //setTransactionType('crypto')
                            setPaymentMethodId(null)
                            //setUsdAmount('')
                            //setAmount('');
                            // setMt5Number(null);
                            setTransactionId('');
                            setUtrId('');
                            setScreenshot(null);
                            //  setSelectedFiles([]);
                            setRequestSubmitSuccsess(true);
                            setRequestSubmitSuccessMsg("Challange request sent successfully.You will get notification in email once it will be approved");
                            setTimeout(() => {
                                navigate('/home');
                            }, 2000);

                        } else {
                            setRequestSubmitError(true)
                            setRequestSubmitErrorMsg(response.data.data.error)
                        }
                        setIsLoading(false);
                    })
                    .catch(error => {
                        setIsLoading(false);
                        setRequestSubmitError(true)
                        setRequestSubmitErrorMsg(error.response.data.message)
                        //console.error('Error uploading profile picture:', error);
                    });
            } catch (error) {
                //console.error('Error uploading files:', error);
            }
        } else {
            // Set errors to state
            setErrors(valErrors);
        }
    };

    const handleCopyUpiId = () => {
        setRequestSubmitSuccsess(false);
        setRequestSubmitError(false);
        navigator.clipboard.writeText(selectedPaymentMethod.upi_id).then(() => {
            setRequestSubmitSuccsess(true);
            setRequestSubmitSuccessMsg('Deposit address copied to clipboard!');
            //   console.log('Deposit address copied to clipboard!');
            // Add user feedback here if desired
        }).catch(err => {
            console.error('Failed to copy the address: ', err);
        });
    }

    const handleCopyAddress = () => {
        setRequestSubmitSuccsess(false);
        setRequestSubmitError(false);
        navigator.clipboard.writeText(selectedPaymentMethod.deposit_address).then(() => {
            setRequestSubmitSuccsess(true);
            setRequestSubmitSuccessMsg('Deposit address copied to clipboard!');
            // setRequestSubmitSuccessMsg('Deposit address copied to clipboard!');
            //   console.log('');
            // Add user feedback here if desired
        }).catch(err => {
            console.error('Failed to copy the address: ', err);
        });
    }

    const handleCopy = (copyText) => {
        setRequestSubmitSuccsess(false);
        setRequestSubmitError(false);
        navigator.clipboard.writeText(copyText).then(() => {
            setRequestSubmitSuccsess(true);
            setRequestSubmitSuccessMsg('Copied to clipboard!');
            // setRequestSubmitSuccessMsg('Deposit address copied to clipboard!');
            //   console.log('');
            // Add user feedback here if desired
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    }

    // Demo On Change function
    const onChange = () => { };

    return (
        <>
            {!dataLoaded && <Loaders />}

            {dataLoaded &&
                <>

                    <div className="pattern-bx">
                        <div className="gradient-background-bx">
                            <div className="gb-inner">
                                <div className="gb-shape gb-s-2"></div>
                            </div>
                            {/* <div className="gb-noice"></div> */}
                        </div>
                        <img className='line-pattern lp-tr' src={Img.Linepattern} alt="" />
                        <img className='line-pattern lp-bl' src={Img.Linepattern} alt="" />
                    </div>

                    <div className="container-lg cl-custome3">
                        <div className="challenge-form-box">

                            <div className={`common-box cb-all-none mb-4 mt-2 ${challengeStep === 'step3' ? 'cb-866width' : ''}`}>
                                <div className="common-box-heading">Create challenge account</div>

                                {/* --- Steps buttons start --- */}
                                <div className="common-box cb-color2">
                                    <div className="challenge-steps-bx">
                                        <div className={`csb-item ${challengeStep === 'step1' || challengeStep === 'step2' || challengeStep === 'step3' ? 'complated' : ''} ${challengeStep === 'step1' ? 'active' : ''}`}>
                                            <div className="csb-i-num">1</div>
                                            <div className="csb-i-label">Configure</div>
                                            <div className="csb-progress-bar">
                                                <div className="csb-progress-inner"></div>
                                            </div>
                                        </div>
                                        <div className={`csb-item ${challengeStep === 'step2' || challengeStep === 'step3' ? 'complated' : ''} ${challengeStep === 'step2' ? 'active' : ''}`}>
                                            <div className="csb-i-num">2</div>
                                            <div className="csb-i-label">Verify</div>
                                            <div className="csb-progress-bar">
                                                <div className="csb-progress-inner"></div>
                                            </div>
                                        </div>
                                        <div className={`csb-item ${challengeStep === 'step3' ? 'complated' : ''} ${challengeStep === 'step3' ? 'active' : ''}`}>
                                            <div className="csb-i-num">3</div>
                                            <div className="csb-i-label">Pay</div>
                                        </div>
                                    </div>
                                </div>
                                {/* --- Steps buttons end --- */}
                            </div>

                            {/* --- Challenge Step 1 start  --- */}
                            <div className={`common-box cb-all-none ${challengeStep === 'step1' ? '' : 'd-none'} `} id="step1">

                                <div className="common-box cb-color2">
                                    <div className="common-box-heading cbh-16small mb-0">Configure your account</div>
                                    <div className="row">
                                        <div className="col-12"><div className="common-section-separator"></div></div>
                                        <div className="col-12">
                                            <SelectInput heading="Choose your country" options={countries} selectedValue={selectedValue} onChange={handleCountryChange} defaultOption={'Select Country'} value={countryId} errorMeg={errors?.country} error={errors?.country} />
                                        </div>

                                        <div className="col-12">
                                            <div className="sgr-outer-label">Choose your account type</div>
                                            <div className="signinup-group">
                                                <div className="sg-list-bx slb-style-2">
                                                    {accountTypes && accountTypes.map((option, index) => (
                                                        option.step !== 'HFT' && (
                                                            <div className={`sg-list-item ${accountTypeId === option.id ? 'active' : ''}`} key={index} onClick={() => handleAccountTypeChange(option.id)}>{option.step}</div>
                                                        )
                                                    ))}
                                                    {/* <div className="sg-list-item">2 Step</div>
                                                <div className="sg-list-item">3 Step</div> */}
                                                </div>
                                                {/* <span className="form-error-msg">errorMeg</span> */}
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="sgr-outer-label">Platform</div>
                                            {platforms && platforms.map((option, index) => (
                                                <div className="signinup-group" key={index}>
                                                    <div className="sg-list-bx slb-style-2 slb-img">
                                                        <div className="sg-list-item active">
                                                            <img src={mt5} alt={option.name} />
                                                        </div>
                                                    </div>
                                                    {/* <span className="form-error-msg">errorMeg</span> */}
                                                </div>
                                            ))}
                                        </div>

                                        <div className="col-12">
                                            <div className="sgr-outer-label">Choose account size</div>
                                            <div className="signinup-group mb-0">
                                                <div className="sg-list-bx slb-style-2">
                                                    {accountSizes && accountSizes.map((option, index) => (
                                                        <div className={`sg-list-item ${accountSizeId === option.id ? 'active' : ''}`} key={index} onClick={() => handleAccountSizeChange(option.id)}>${option.limit}</div>
                                                    ))}
                                                    {/* <div className="sg-list-item">$10,000</div>
                                                <div className="sg-list-item">$25,000</div>
                                                <div className="sg-list-item">$50,000</div>
                                                <div className="sg-list-item">$100,000</div> */}
                                                </div>
                                                {/* <span className="form-error-msg">errorMeg</span> */}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="common-box cb-all-none mt-4">
                                    <div className="common-box-heading cbh-20big text-center">Selected plan detail</div>

                                    <div className="challenge-detail common-box cb-color2">
                                        <img className="cd-img" src={Img.icon} alt="" />
                                        <div className="cd-heading">{selectedAccountSize?.name} {selectedAccountType?.step} {selectedAccountType?.name}</div>
                                        {/* <div className="cd-pera">Account Size {selectedAccountSize?.limit} USD</div> */}
                                        <div className="cd-trader">Meta Trader 5</div>
                                        <div className="cd-amount">${selectedAccountSize?.price}</div>
                                        <div className="common-section-separator"></div>
                                        <div className="cd-rules-info">
                                            <div className="cd-r-item"><Icons.Check2Svg /> {selectedAccountSize?.min_trade_days} Min Trading Days</div>
                                            <div className="cd-r-item"><Icons.Check2Svg /> {selectedAccountSize?.profit_target}% Profit Target (Phase 1)</div>
                                            {selectedAccountSize?.profit_target_phase2 !== null && selectedAccountSize?.profit_target_phase2 !== undefined && (
                                                <div className="cd-r-item">
                                                    <Icons.Check2Svg /> {selectedAccountSize?.profit_target_phase2}% Profit Target (Phase 2)
                                                </div>
                                            )}
                                            <div className="cd-r-item"><Icons.Check2Svg /> {selectedAccountSize?.max_overall_loss}% Max Overall Loss</div>
                                            <div className="cd-r-item"><Icons.Check2Svg /> {selectedAccountSize?.max_daily_loss}% Max Daily Loss</div>
                                        </div>
                                        <div className="cd-more-info">For a more details <Link to='https://www.fundedfirm.com/' target='_blank'>Visit here</Link></div>
                                        <CheckInput className="cd-sgc-ci" name="ResidentCheck" id="ResidentCheck" label="I am not a US resident or citizen." onChange={handleNotUsCitizenChange} checked={notUsCitizen ? true : false} value={notUsCitizen} />
                                        <div onClick={() => handleStepClick('step2')} className="common-submit-btn csb-outline csb-gradient csb-width-full mb-0"><span>Next Step</span></div>
                                    </div>
                                </div>

                            </div>
                            {/* --- Challenge Step 1 start  --- */}

                            {/* --- Challenge Step 2 start  --- */}
                            <div className={`common-box cb-color2 ${challengeStep === 'step2' ? '' : 'd-none'} `} id="step2">
                                <div onClick={() => handleStepClick('step1')} className="common-back-btn cbb-absolute p-0"><Icons.ArrowBackSvg />Back</div>
                                <div className="common-box-heading cbh-16small text-center mb-0">Billing Details</div>
                                <div className="row">
                                    <div className="col-12"><div className="common-section-separator"></div></div>

                                    <div className="col-12">
                                        <SelectInput heading="Title" options={TitleOptions} selectedValue={selectedValue} onChange={handleTitleChange} errorMeg={errors?.title} error={errors?.title} value={title} />
                                    </div>

                                    <div className="col-12"></div>

                                    <div className="col-6">
                                        <Input heading="First Name" type="text" placeholder="Enter first name" onChange={handleFirstNameChange} errorMeg={errors?.first_name} error={errors?.first_name} value={firstName} />
                                    </div>

                                    <div className="col-6">
                                        <Input heading="Last name" type="text" placeholder="Enter last name" onChange={handleLastNameChange} errorMeg={errors?.last_name} error={errors?.last_name} value={lastName} />
                                    </div>

                                    <div className="col-sm-12">
                                        <Input heading="Street" type="text" placeholder="Enter Street" onChange={handleStreetChange} errorMeg={errors?.street} error={errors?.street} value={street} />
                                    </div>

                                    <div className="col-sm-6">
                                        <Input heading="City" type="text" placeholder="Enter City" onChange={handleCityChange} errorMeg={errors?.city} error={errors?.city} value={city} />
                                    </div>

                                    <div className="col-sm-6">
                                        <Input heading="Postal code" type="text" placeholder="Enter Postal code" onChange={handlePostalCodeChange} errorMeg={errors?.postal_code} error={errors?.postal_code} value={postalCode} />
                                    </div>

                                    <div className="col-12">
                                        <SelectInput heading="Country" options={countries} selectedValue={selectedValue} onChange={handleAddressCountryChange} errorMeg={errors?.address_country} error={errors?.address_country} value={addressCountry} defaultOption={'Select Country'} />
                                    </div>

                                    {/* <div className="col-sm-6">
                                    <Input heading="Date Of Birthy" type="date" placeholder="Enter date of birth" errorMeg="Date of birth is required" />
                                </div> */}

                                    <div className="col-12 mt-1">
                                        <div onClick={handleAgreeModalShow} className="common-submit-btn csb-width-full">Proceed To Pay</div>
                                        {/* <div className="lsb-loader-btn"><img src={Img.loading} alt="" />Please wait ....</div> */}
                                    </div>

                                </div>
                            </div>
                            {/* --- Challenge Step 2 End  --- */}

                            {/* --- Challenge Step 3 start  --- */}
                            <div className={` challenge-payment-flex ${challengeStep === 'step3' ? '' : 'd-none'} `} id="step3">

                                {/* challenge payment left section start */}
                                <div className="cpf-left">

                                    {/* Payment Step1 */}
                                    <div className={`common-box cb-color2 ${paymentStep === 'paymentStep1' ? '' : 'd-none'} `} id="paymentStep1">
                                        <div onClick={() => handleStepClick('step2')} className="common-back-btn cbb-absolute p-0"><Icons.ArrowBackSvg />Back</div>
                                        <div className="common-box-heading cbh-16small mb-0 text-center">Choose payment method</div>

                                        <div className="row row-gap-3">
                                            <div className="col-12">
                                                <div className="common-section-separator mb-0"></div>
                                            </div>
                                            {filteredPaymentMethods && filteredPaymentMethods.map((option, index) => (
                                                <PaymentMethodItem
                                                    src={option.image_url}
                                                    alt={option.name}
                                                    heading={option.name}
                                                    key={index}
                                                    onClick={() => handlePaymentMethodIdChange(option.id)}
                                                />
                                            ))}
                                        </div>

                                    </div>


                                    {/* Payment Step2 */}
                                    <div className={`common-box cb-color2 ${paymentStep === 'paymentStep2' ? '' : 'd-none'} `} id="paymentStep2">
                                        <div onClick={() => handlePaymentStepClick('paymentStep1')} className="common-back-btn cbb-absolute p-0"><Icons.ArrowBackSvg />Back</div>
                                        <div className="common-box-heading cbh-16small text-center">Payment Details</div>
                                        <div className="col-12"><div className="common-section-separator"></div></div>

                                        <div className={`common-box-info ${paymentTab === 'QRCode' ? '' : 'd-none'}`} onClick={handleInfoShow} ><Icons.InfoSvg /></div>


                                        {/* Payment tab buttons start */}
                                        {selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '' &&
                                            <div className={`payment-tab-bx`}>
                                                <div className={`payment-tab-item ${paymentTab === 'QRCode' ? 'active' : ''}`} onClick={() => handlePaymentClick('QRCode')}>
                                                    <img className="payment-img" src={Img.qricon} alt="" />
                                                    Scan QR
                                                </div>

                                                <div className={`payment-tab-item ${paymentTab === 'BankTransfer' ? 'active' : ''}`} onClick={() => handlePaymentClick('BankTransfer')}>
                                                    <img className="payment-img" src={Img.bankicon} alt="" />
                                                    Bank Detail
                                                </div>
                                            </div>
                                        }


                                        {/* Payment tab buttons end */}


                                        {/* UPI ID box start */}
                                        {selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '' &&
                                            <div className="payment-data-bx mb-3">
                                                <div className="pdb-item upi-item">
                                                    <div className="pdb-i-lable">UPI ID</div>
                                                    <div className="pdb-i-data pid-style-2">{selectedPaymentMethod.upi_id}
                                                        <span className="pdb-i-icon" title="UPI ID" onClick={handleCopyUpiId}><Icons.CopySvg /></span> </div>
                                                </div>
                                            </div>
                                        }
                                        {/* UPI ID box end */}


                                        {/* QR code box start */}
                                        {selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '' &&
                                            <div className={`payment-data-bx pdb-style-3 ${paymentTab === 'QRCode' ? '' : 'd-none'} `} id="QRCode">
                                                <div className="qr-code-box">
                                                    <img src={selectedPaymentMethod.qr_code_image_url} alt={selectedPaymentMethod.name} />
                                                </div>
                                            </div>
                                        }
                                        {/* QR code box end */}


                                        {/* Bank detail box start */}
                                        {selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '' &&
                                            <div className={`payment-data-bx pdb-style-2 ${paymentTab === 'BankTransfer' ? '' : 'd-none'} `} id="BankTransfer">
                                                <div className="pdb-item">
                                                    <div className="pdb-i-lable">Account Name:</div>
                                                    <div className="pdb-i-data">{(selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '') ? selectedPaymentMethod.account_name : ''}</div>
                                                </div>

                                                <div className="pdb-item">
                                                    <div className="pdb-i-lable">Bank Name:</div>
                                                    <div className="pdb-i-data">{(selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '') ? selectedPaymentMethod.bank_name : ''}</div>
                                                </div>

                                                <div className="pdb-item">
                                                    <div className="pdb-i-lable">Branch:</div>
                                                    <div className="pdb-i-data">{(selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '') ? selectedPaymentMethod.branch : ''}</div>
                                                </div>

                                                <div className="pdb-item">
                                                    <div className="pdb-i-lable">Account Number:</div>
                                                    <div className="pdb-i-data pid-style-2">{(selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '') ? selectedPaymentMethod.account_number : ''}
                                                        <span className="pdb-i-icon" onClick={() => handleCopy((selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '') ? selectedPaymentMethod.account_number : '')} title="Copy account number"><Icons.CopySvg /></span> </div>
                                                </div>

                                                <div className="pdb-item">
                                                    <div className="pdb-i-lable">IFSC Code:</div>
                                                    <div className="pdb-i-data pid-style-2">{(selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '') ? selectedPaymentMethod.ifsc_code : ''}
                                                        <span className="pdb-i-icon" onClick={() => handleCopy((selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '') ? selectedPaymentMethod.ifsc_code : '')} title="Copy IFSC code"><Icons.CopySvg /></span> </div>
                                                </div>
                                            </div>
                                        }
                                        {/* Bank detail box end */}


                                        {/* USDT detail box start */}
                                        {selectedPaymentMethod && selectedPaymentMethod.deposit_address && selectedPaymentMethod.deposit_address !== '' &&
                                            <div className={`payment-data-bx pdb-style-2 mt-4`}>

                                                <div className="pdb-item">
                                                    <div className="pdb-i-lable">USDT</div>
                                                    <div className="pdb-i-data"> {selectedPaymentMethod.name}</div>
                                                </div>

                                                <div className="pdb-item">
                                                    <div className="pdb-i-lable">Deposit Address:</div>
                                                    <div className="pdb-i-data pid-style-2 common-word-wrap">{selectedPaymentMethod?.deposit_address}
                                                        <span className="pdb-i-icon" title="Copy deposit address" onClick={handleCopyAddress}><Icons.CopySvg /></span>
                                                    </div>
                                                </div>

                                                <div className={`payment-data-bx pdb-style-3`}>
                                                    <div className="qr-code-box">
                                                        <img src={selectedPaymentMethod?.qr_code_image_url} alt='' />
                                                    </div>
                                                </div>

                                                <div className="pdb-item">
                                                    <div className="pdb-i-lable">Procedure:</div>
                                                    <div className="pdb-i-data">1 - Complete the trasnfer to the {selectedPaymentMethod.name} deposit address.</div>
                                                    <div className="pdb-i-data">2 - Once you get the confirmation, then enter the amount transferred along with the Proof of Transfer. - <strong>Proceed</strong> </div>
                                                    <div className="pdb-i-data">3 - We will verify your transaction and automatically approve your Deposit.</div>
                                                </div>

                                            </div>
                                        }
                                        {/* USDT detail box end */}


                                        <div className="input-note mt-4 mb-2"><Icons.InfoSvg /> Point your smartphone’s camera at the QR code provided by the deposit service. Ensure the QR code is within the frame and clearly visible.</div>


                                        <div className="mt-4">

                                            {/* {selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '' &&
                                            <button className="common-submit-btn csb-width-full" onClick={changePaymentMethod} >Try With Another UPI</button> } */}
                                            {selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '' &&
                                                paymentMethods.filter(method => method.upi_id && method.upi_id !== '' && method.id !== selectedPaymentMethod?.id).length > 0 &&
                                                <button className="common-submit-btn csb-width-full" onClick={changePaymentMethod} >Try With Another UPI</button>}

                                            <div onClick={() => handlePaymentStepClick('paymentStep3')} className="common-submit-btn csb-width-full">Continue</div>
                                            {/* <div className="lsb-loader-btn"><img src={Img.loading} alt="" />Please wait ....</div> */}
                                        </div>

                                    </div>


                                    {/* Payment Step3 */}
                                    <div className={`common-box cb-color2 ${paymentStep === 'paymentStep3' ? '' : 'd-none'} `} id="paymentStep3">
                                        <div onClick={() => handlePaymentStepClick('paymentStep2')} className="common-back-btn cbb-absolute p-0"><Icons.ArrowBackSvg />Back</div>
                                        <div className="common-box-heading cbh-16small text-center mb-0">Fill your payment details</div>
                                        <div className="row">
                                            <div className="col-12"><div className="common-section-separator"></div></div>
                                            <div className="col-12">
                                                {/* <Input heading="Amount" type="number" placeholder="Enter amount" onChange={handleAmountChange} value={(selectedPaymentMethod && selectedPaymentMethod.deposit_address && selectedPaymentMethod.deposit_address !== '') ? (amount ? amount : selectedAccountSize?.price) : convertAmount.toFixed(2)} readOnly={true} /> */}
                                                <Input
                                                    heading="Amount"
                                                    type="number"
                                                    placeholder="Enter amount"
                                                    onChange={handleAmountChange}
                                                    value={selectedPaymentMethod &&
                                                        selectedPaymentMethod.upi_id
                                                        ? (appliedCoupon.discountType === 'rupees'
                                                            ? (convertAmount - appliedCoupon.discount).toFixed(2)
                                                            : (convertAmount * (1 - appliedCoupon.discount / 100)).toFixed(2))
                                                        : (appliedCoupon.discountType === 'rupees'
                                                            ? (selectedAccountSize?.price - appliedCoupon.discount).toFixed(2)
                                                            : (selectedAccountSize?.price * (1 - appliedCoupon.discount / 100)).toFixed(2))
                                                    }
                                                    readOnly={true}
                                                />
                                            </div>
                                            {selectedPaymentMethod?.name === "UPI" && paymentTab === 'QRCode' ? (
                                                //     <div className="col-12">
                                                //     <Input heading="UTRID" type="text" placeholder="Enter UTRID" onChange={handleUtrId} errorMeg={errors?.utr_id} error={errors?.utr_id} value={utrId} />
                                                // </div>
                                                <div className="col-12">
                                                    <Input
                                                        heading="UTRID"
                                                        type="text"
                                                        placeholder="Enter UTRID"
                                                        onChange={handleUtrId}
                                                        errorMeg={errors?.utr_id}
                                                        error={errors?.utr_id}
                                                        value={utrId}
                                                    />
                                                </div>
                                            ) : (

                                                <div className="col-12">
                                                    <Input heading="TXID" type="text" placeholder="Enter TXID" onChange={handleTransactionId} errorMeg={errors?.transaction_id} error={errors?.transaction_id} value={transactionId} />
                                                </div>
                                            )}
                                            <div className="col-12">
                                                <FileUpload
                                                    acceptedFileTypes="image/jpeg, image/png"
                                                    maxFiles={1}
                                                    maxFileSize={5}
                                                    onFileUpload={handleFileUpload}
                                                />
                                                {errors.screenshot && <span className="form-error-msg">{errors.screenshot}</span>}
                                            </div>

                                            <div className="col-12 mt-3">
                                                {!isLoading && <div className="common-submit-btn csb-width-full" onClick={handleSubmit}>Submit</div>
                                                }
                                                {isLoading && <div className="lsb-loader-btn">
                                                    <img src={Img.loading} alt="" />Please wait ....
                                                </div>
                                                }

                                                {/* <div className="lsb-loader-btn"><img src={Img.loading} alt="" />Please wait ....</div> */}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                {/* challenge payment left section end */}


                                {/* challenge payment right section start */}
                                <div className="cpf-right">
                                    <div className="common-box cb-color2 common-sticky" style={{ height: "100%" }}>
                                        {((paymentStep == 'paymentStep1') || (selectedPaymentMethod && selectedPaymentMethod.deposit_address && selectedPaymentMethod.deposit_address !== '')) &&
                                            <div className="row">

                                                {paymentStep === 'paymentStep2' && (
                                                    showCoupon ? (
                                                        <>
                                                            <div className="col-12">
                                                                <div className="common-section-heading mb-2 mt-0">Coupon code</div>
                                                                <CloseIcon className="couponClose" onClick={toggleCoupon} />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="d-flex column-gap-2">
                                                                    <Input
                                                                        className="mb-0 w-100"
                                                                        type="text"
                                                                        placeholder="Enter your coupon code"
                                                                        value={couponCode}
                                                                        onChange={(value) => setCouponCode(value)}
                                                                        errorMeg={couponError}
                                                                    />
                                                                    <div className="common-submit-btn csb-width-fit mb-0" onClick={applyCoupon}>
                                                                        Apply
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12"><div className="common-section-separator"></div></div>
                                                        </>
                                                    ) : (
                                                        <div className="col-12">
                                                            <div className="common-section-heading csh-coupon mb-2 mt-0" onClick={toggleCoupon}>have a coupon?</div>
                                                        </div>
                                                    )
                                                )}

                                                <div className="col-12">
                                                    <div className="common-box-heading cbh-16small mb-0 mt-0">Challenge summary</div>
                                                </div>

                                                <div className="col-12"><div className="common-section-separator mb-0"></div></div>

                                                <div className="col-12">
                                                    <div className="challenge-payment-details">
                                                        <div className="cpd-item">
                                                            <div className="cpd-title">
                                                                {selectedAccountSize?.name} {selectedAccountType?.step} {selectedAccountType?.name}
                                                            </div>
                                                            <div className="cpd-amount">${selectedAccountSize?.price}</div>
                                                        </div>

                                                        {appliedCoupon.discount > 0 && (
                                                            <div className="cpd-item">
                                                                <div className="cpd-title">Coupon: {appliedCoupon.code}</div>
                                                                <div className="cpd-amount cpd-a-highlight">
                                                                    {appliedCoupon.discountType === 'rupees'
                                                                        ? `- $${appliedCoupon.discount}`
                                                                        : `- ${appliedCoupon.discount}%`}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="common-section-separator mt-0"></div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="challenge-payment-details">
                                                        <div className="cpd-item cpd-gt-item">
                                                            <div className="cpd-title">Total</div>
                                                            <div className="cpd-amount">
                                                                ${appliedCoupon.discountType === 'rupees'
                                                                    ? (selectedAccountSize?.price - appliedCoupon.discount).toFixed(2)
                                                                    : (selectedAccountSize?.price * (1 - appliedCoupon.discount / 100)).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        }

                                        {selectedPaymentMethod && selectedPaymentMethod.upi_id && selectedPaymentMethod.upi_id !== '' &&
                                            <div className="row">
                                                {paymentStep === 'paymentStep2' && (
                                                    showCoupon ? (
                                                        <>
                                                            <div className="col-12">
                                                                <div className="common-section-heading mb-2 mt-0">Coupon code</div>
                                                                <CloseIcon className="couponClose" onClick={toggleCoupon} />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="d-flex column-gap-2">
                                                                    <Input
                                                                        className="mb-0 w-100"
                                                                        type="text"
                                                                        placeholder="Enter your coupon code"
                                                                        value={couponCode}
                                                                        onChange={(value) => setCouponCode(value)}
                                                                        errorMeg={couponError}
                                                                    />
                                                                    <div className="common-submit-btn csb-width-fit mb-0" onClick={applyCoupon}>
                                                                        Apply
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12"><div className="common-section-separator"></div></div>
                                                        </>
                                                    ) : (
                                                        <div className="col-12">
                                                            <div className="common-section-heading csh-coupon mb-2 mt-0" onClick={toggleCoupon}>have a coupon?</div>
                                                        </div>
                                                    )
                                                )}

                                                <div className="col-12">
                                                    <div className="common-box-heading cbh-16small mb-0 mt-0">Challenge summary</div>
                                                </div>
                                                <div className="col-12"><div className="common-section-separator mb-0"></div></div>

                                                <div className="col-12">
                                                    <div className="challenge-payment-details">
                                                        <div className="cpd-item">
                                                            <div className="cpd-title">
                                                                {selectedAccountSize?.name} {selectedAccountType?.step} {selectedAccountType?.name}
                                                            </div>
                                                            <div className="cpd-amount">₹{convertAmount.toFixed(2)}</div>
                                                        </div>

                                                        {appliedCoupon.discount > 0 && (
                                                            <div className="cpd-item">
                                                                <div className="cpd-title">Coupon: {appliedCoupon.code}</div>
                                                                <div className="cpd-amount cpd-a-highlight">
                                                                    {appliedCoupon.discountType === 'rupees'
                                                                        ? `- ₹${(appliedCoupon.discount * convertAmount / selectedAccountSize?.price).toFixed(2)}`
                                                                        : `- ₹${(convertAmount * appliedCoupon.discount / 100).toFixed(2)}`}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="common-section-separator mt-0"></div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="challenge-payment-details">
                                                        <div className="cpd-item cpd-gt-item">
                                                            <div className="cpd-title">Total</div>
                                                            <div className="cpd-amount">
                                                                ₹{appliedCoupon.discountType === 'rupees'
                                                                    ? (convertAmount - appliedCoupon.discount).toFixed(2)
                                                                    : (convertAmount * (1 - appliedCoupon.discount / 100)).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <FlashMessage type="success" isVisible={couponSuccess} message={couponSuccessMsg} />
                                        <FlashMessage type="error" isVisible={couponError} message={couponErrorMsg} />

                                    </div>
                                </div>
                                {/* challenge payment right section end */}

                            </div>
                            {/* --- Challenge Step 3 end  --- */}

                        </div>
                    </div>
                </>
            }

            {/* --- Agree modal start --- */}
            <Modal className="zoom custom-content" centered show={isAgreeModal} onHide={handleAgreeModalClose}>
                <div className="custom-modal-header">

                    <div className="cmh-lable">Before you proceed</div>
                    <div className="cmh-sub-lable">Please agree to the trading rules</div>

                    <CloseIcon onClick={handleAgreeModalClose} />
                </div>

                <div className="custom-modal-body">
                    <div className="challenge-trading-rules">
                        <div className="ctr-item">
                            <Icons.Check2Svg />
                            <div className="ctr-data">I have read and agreed to the <Link to="https://fundedfirm.com/trading-rules" target='_blank'>Trading Rules</Link>.</div>
                        </div>
                        <div className="ctr-item">
                            <Icons.Check2Svg />
                            <div className="ctr-data">I declare that all information filled are correct and corresponds to government issued identification.</div>
                        </div>
                        <div className="ctr-item">
                            <Icons.Check2Svg />
                            <div className="ctr-data">I declare that I have read and agreed with the <Link to="https://fundedfirm.com/terms-conditions" target='_blank'>Terms & Conditions</Link>.</div>
                        </div>
                    </div>
                    <div className="col-12 mt-3 mb-4">
                        <div onClick={() => handleStepClick('step3')} className="common-submit-btn csb-width-full">I Agree</div>
                        {/* <div className="lsb-loader-btn"><img src={Img.loading} alt="" />Please wait ....</div> */}
                    </div>
                </div>
            </Modal>
            {/* --- Agree modal end --- */}

            {/* --- QR Code Info Modal Start --- */}
            <Modal className="zoom custom-content" centered show={infoShow} onHide={handleInfoClose}>
                <div className="custom-modal-header">
                    <div className="cmh-lable">Notice</div>
                    <CloseIcon onClick={handleInfoClose} />
                </div>

                <div className="custom-modal-body">
                    <div className="legal-content">
                        <div className="legal-heading">How to scan QR code via mobile phone</div>
                        <ul>
                            <li>How to scan QR code via mobile phone</li>
                            <li>Take a screenshot* of the QR code.</li>
                            <li>Open your UPI app, select the QR code scanner and scan it from image.</li>
                            <li>You’ll be able to browse through your files to find the screenshot with the QR code.</li>
                            <li>Confirm the payment and you’re done!</li>
                        </ul>
                    </div>
                    <div className="legal-content">
                        <div className="legal-heading">How to scan QR code via desktop</div>
                        <ul>
                            <li>Open your UPI app on mobile phone, select the QR code scanner.</li>
                            <li>Hold your phone over the QR code on desktop display so that it’s clearly visible within the scan area.</li>
                            <li>Confirm the payment and you’re done!</li>
                        </ul>
                    </div>
                </div>
            </Modal>
            {/* --- QR Code Info Modal End --- */}

            <FlashMessage type="success" isVisible={requestSubmitSuccess} message={requestSubmitSuccessMsg} />
            <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
        </>
    )
}
