import React, { useEffect, useState } from 'react';
import CloseIcon from './CloseIcon';

function FlashMessage({ type, isVisible, message }) {
  const [isPopupVisible, setPopupVisible] = useState(isVisible);

  useEffect(() => {
    setPopupVisible(isVisible);
    let popupTimer;

    if (isVisible) {
      // Start a timer to hide the popup after 3 seconds
      popupTimer = setTimeout(() => {
        setPopupVisible(false);
      }, 3000);
    }

    return () => {
      // Clear the timer when the component unmounts or when isVisible changes
      clearTimeout(popupTimer);
    };
  }, [isVisible]);

  const handlefmpclose = () => {
    setPopupVisible(false);
  };

  return (
    <>
      <div className={`flash-message ${isPopupVisible ? 'fmp-message' : ''}`}>
        <div className="flash-message-inner">

          <CloseIcon onClick={handlefmpclose} />

          {type === 'success' && (
            <svg className="flash-success-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
          )}

          {type === 'error' && (
            <svg className="flash-error-svg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
          )}

          <div className="flash-text">
            {message}
          </div>
        </div>
        <div className="flash-time-line"></div>
      </div>

      <div className={`flash-message-popup ${isPopupVisible ? 'fmp-active' : ''}`} onClick={handlefmpclose}></div>
    </>
  );
}

export default FlashMessage;
