import React from 'react'
import Icons from './icons'

export default function CalendarNavigation({className="", prev, next, date }) {
  return (
    <div className={`calendar-navigation-bx ${className}`}>
        <div className="cnb-btn" onClick={prev}><Icons.CaretLeftSvg /></div>
        <div className="cnb-date">{date}</div>
        <div className="cnb-btn" onClick={next}><Icons.CaretRightSvg /></div>
    </div>
  )
}
