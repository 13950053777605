import React from 'react';
import tpmMeter from "../../img/icons/tpm-meter.png";
import tpmPointer from "../../img/icons/tpm-pointer.png";

export default function MeterChart({ className = " ", src = tpmMeter, percentage, label }) {
  // Ensure percentage is within a valid range (0 to 100)
  const validPercentage = Math.min(Math.max(percentage, 0), 100);

  // Calculate the degree based on the percentage (0% = 0deg, 100% = 180deg)
  const degree = (validPercentage / 100) * 180;

  return (
    <div className={`meter-chart-bx ${className}`}>
      <img className="mcb-meter-img" src={src} alt="Meter" />
      <img
        style={{ transform: `rotate(${degree}deg)` }}
        className="mcb-meter-pointer"
        src={tpmPointer}
        alt="Pointer"
      />
      {label && (
        <div className="mcb-data-bx">
          <div className="mcb-label">{label}</div>
          <div className="mcb-value">{validPercentage}%</div>
        </div>
      )}
    </div>
  );
}
