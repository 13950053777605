import React from 'react';

export default function PredictionCard({ className="", impact, statusClass, currency, event, actual, forecast, previous, text, onClick }) {
  return (
    <div className={`prediction-card-item ${className}`} onClick={onClick}>
      {impact && <div className={`pci-impact ${statusClass || ''}`}>{impact}</div>}
      {currency && <div className="pci-currency">{currency}</div>}
      {event && <div className="pci-event">{event}</div>}
      {(actual || forecast || previous) && <div className="pci-separator"></div>}
      {actual && <div className="pci-data">Actual <span>{actual}</span></div>}
      {forecast && <div className="pci-data">Forecast <span>{forecast}</span></div>}
      {previous && <div className="pci-data">Previous <span>{previous}</span></div>}
      {text && <div className="pci-text">{text}</div>}
    </div>
  );
}
