import React from "react";
import {Link} from "react-router-dom";

import Telegram from "../img/icons/social/telegram.png"
import Instagram from "../img/icons/social/instagram.png"
import Whatsapp from "../img/icons/social/whatsapp.png"
import Support from "../img/icons/social/support.png"

export default function Socialicon2({className=""}) {

  return (
    <>
    <div className={`social-icon-bx ${className}`}>
      <Link className={`sib-item`} target="_blank" to="https://t.me/fundedfirm">
        <img src={Telegram} alt="" />
      </Link>
      <Link className={`sib-item`} target="_blank" to="https://www.instagram.com/fundedfirm/?hl=en">
        <img src={Instagram} alt="" />
      </Link>
      <Link className={`sib-item`} target="_blank" to="https://api.whatsapp.com/message/KL5HIQ3XMD65P1">
        <img src={Whatsapp} alt="" />
      </Link>
      <Link className={`sib-item`} target="_blank" to="https://fundedfirm.com/contact">
        <img src={Support} alt="" />
      </Link>
    </div>
    </>
  );
};
