import React from 'react'
import { Link } from 'react-router-dom';

import logoIcon from "../img/logo/icon.png"
import logoText from "../img/logo/fundedfirmtext.png"

export default function Logo({className, to="/"}) {
  return (
    <Link to={to} className={`${className}`}>
        <img className="logo-icon" src={logoIcon} alt="" />
        <img className="logo-text" src={logoText} alt="" />
    </Link>
  )
}
