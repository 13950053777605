import React, { useState, useRef, useEffect } from 'react'
import * as Img from '../../components/Img';
import Icons from '../../components/icons'


import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import FlashMessage from '../../components/FlashMessage';
import Button from 'react-bootstrap/Button';
import EmptyBox from '../../components/EmptyBox';
import Loaders from '../../components/Loader';
import Pagination from '../../components/Pagination';


export default function PayoutHistory() {

  //Auth related var

  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);

  const { id } = useParams();

  //Loader
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);

  const [payoutsData, setPayoutsData] = useState([]);
  const [pagePayoutsData, setPagePayoutsData] = useState(1);
  const [goPagePayoutsData, setGoPagePayoutsData] = useState(null);
  const [totalPagesPayoutsData, setTotalPagesPayoutsData] = useState(0);
  const [sortField, setSortField] = useState('close_date'); // Default sort by date
  const [sortDirection, setSortDirection] = useState('desc'); // Default direction

  // const onChangePagePayoutsData = (pagePayoutsData) => {
  //   if (pagePayoutsData.target.value == '') {
  //     pagePayoutsData.target.value = 1;
  //   }
  //   if (pagePayoutsData.target.value < parseInt(totalPagesPayoutsData)) {
  //     setPagePayoutsData(pagePayoutsData.target.value);
  //     setGoPagePayoutsData(pagePayoutsData.target.value);
  //   } else {
  //     setPagePayoutsData(totalPagesPayoutsData);
  //     setGoPagePayoutsData(totalPagesPayoutsData);
  //   }
  // }

  const onChangePagePayoutsData = (pagePayoutsData) => {
    setPagePayoutsData(pagePayoutsData)
  }

  const changePrevPagePayoutsData = () => {
    if (pagePayoutsData > 1) {
      setPagePayoutsData(pagePayoutsData - 1);
      onChangePagePayoutsData(pagePayoutsData - 1)
    }

  }
  const changeNextPagePayoutsData = () => {
    console.log(pagePayoutsData);
    console.log(totalPagesPayoutsData);
    if (pagePayoutsData < totalPagesPayoutsData) {
      setPagePayoutsData(pagePayoutsData + 1);
      onChangePagePayoutsData(pagePayoutsData + 1)
    }
  }

  useEffect(() => {
    if (parseInt(pagePayoutsData) > 0) {
      getPayoutsData();
    }
  }, [pagePayoutsData]);

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;

      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            // getIbRequestStatus();
            const profitChart = await getPayoutsData();
            setDataLoaded(true);
            // console.log(tradeHistory);
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error('Failed to fetch ib data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);

  const getPayoutsData = async () => {
    try {
      setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-payouts', {
        user_id: state.userData.id,
        token: token,
        page: pagePayoutsData,
        per_page: 5,
        sort_field: sortField, // Pass sort field
          sort_direction: sortDirection, // Pass sort direction
      });
      if (response.data.success == '0') {
        console.log(response.data.data);
        setPayoutsData(response.data.data.data);
        //   setpayoutsData(response.data.data.data)
        setTotalPagesPayoutsData(response.data.data.last_page)
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSort = (field) => {
    if (sortField === field) {
        // Toggle direction when the same field is clicked
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
        // Set the field to be sorted by, default to ascending
        setSortField(field);
        setSortDirection('asc');
    }
  };
  
  
  // Trigger the fetch when sorting changes
  useEffect(() => {
    getPayoutsData();
  }, [sortField, sortDirection, pagePayoutsData]);

  const anotherFormatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    return date.toLocaleString('en-US', options);
  };

  function getStatusColor(paymentStatus) {
    switch (paymentStatus) {
      case 'pending':
        return 'status-yellow2';
      case 'approved':
        return 'status-green2';
      default:
        return 'status-red2';
    }
  }

  function getStatus(paymentStatus) {
    switch (paymentStatus) {
      case 'approved':
        return '';
      default:
        return 'd-none';
    }
  }
  

  const downloadInvoice = async (payoutID) => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'invoice-generate-and-download', {
        user_id: state.userData.id,
        token: token,
        payout_id: payoutID,
      });
      if (response.data.success == '0') {
        // window.open(response.data.data.download_link);

        //   console.log(response);
        const link = document.createElement('a');
        link.href = response.data.data.download_link;
        link.setAttribute('target', '_blank'); // Open in a new tab

        // link.setAttribute('download', 'passed_certificate.pdf'); // Set default filename (optional)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      }
      // setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const downloadCertificate = async (payoutID) => {
    try {
      // setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'download-pdf', {
        user_id: state.userData.id,
        token: token,
        type: "payout",
        payout_id: payoutID
      });
      if (response.data.success == '0') {
        // window.open(response.data.data.download_link);

        //   console.log(response);
        const link = document.createElement('a');
        link.href = response.data.data.download_link;
        link.setAttribute('target', '_blank'); // Open in a new tab

        // link.setAttribute('download', 'passed_certificate.pdf'); // Set default filename (optional)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      }
      // setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  return (
    <div className="col-12">
      {!dataLoaded && <Loaders />}
      <div className="common-box">
        <div className="common-box-heading">Payout History</div>
        
  <div className="table-responsive custom-table-responsive">
    {/* Table */}
    <table className="custom-table">
      <thead>
        <tr>
          <th>Sr.</th>
          <th onClick={() => handleSort('reference_id')}
              className={sortField === 'reference_id' ? (sortDirection === 'asc' ? 'asc active' : 'desc active') : ''}>Reference Id
            <div className="th-sort-icons">
              <Icons.ChevronUpSvg 
                className={sortField === 'reference_id' && sortDirection === 'asc' ? 'asc' : ''} 
              />
              <Icons.ChevronDownSvg 
                className={sortField === 'reference_id' && sortDirection === 'desc' ? 'desc' : ''} 
              />
            </div>
          </th>
          <th onClick={() => handleSort('payout_type')}
              className={sortField === 'payout_type' ? (sortDirection === 'asc' ? 'asc active' : 'desc active') : ''}>Payout Type
            <div className="th-sort-icons">
              <Icons.ChevronUpSvg 
                className={sortField === 'payout_type' && sortDirection === 'asc' ? 'asc' : ''} 
              />
              <Icons.ChevronDownSvg 
                className={sortField === 'payout_type' && sortDirection === 'desc' ? 'desc' : ''} 
              />
            </div>
          </th>
          <th onClick={() => handleSort('payment_method_id')}
              className={sortField === 'payment_method_id' ? (sortDirection === 'asc' ? 'asc active' : 'desc active') : ''}>Payment Method
            <div className="th-sort-icons">
              <Icons.ChevronUpSvg 
                className={sortField === 'payment_method_id' && sortDirection === 'asc' ? 'asc' : ''} 
              />
              <Icons.ChevronDownSvg 
                className={sortField === 'payment_method_id' && sortDirection === 'desc' ? 'desc' : ''} 
              />
            </div>
          </th>
          <th onClick={() => handleSort('created_at')}
              className={sortField === 'created_at' ? (sortDirection === 'asc' ? 'asc active' : 'desc active') : ''}>Requested On
            <div className="th-sort-icons">
              <Icons.ChevronUpSvg 
                className={sortField === 'created_at' && sortDirection === 'asc' ? 'asc' : ''} 
              />
              <Icons.ChevronDownSvg 
                className={sortField === 'created_at' && sortDirection === 'desc' ? 'desc' : ''} 
              />
            </div>
          </th>
          <th onClick={() => handleSort('payment_status')}
              className={sortField === 'payment_status' ? (sortDirection === 'asc' ? 'asc active' : 'desc active') : ''}>Payment Status
            <div className="th-sort-icons">
              <Icons.ChevronUpSvg 
                className={sortField === 'payment_status' && sortDirection === 'asc' ? 'asc' : ''} 
              />
              <Icons.ChevronDownSvg 
                className={sortField === 'payment_status' && sortDirection === 'desc' ? 'desc' : ''} 
              />
            </div>
          </th>
          <th onClick={() => handleSort('amount')}
              className={sortField === 'amount' ? (sortDirection === 'asc' ? 'asc active' : 'desc active') : ''}>Amount
            <div className="th-sort-icons">
              <Icons.ChevronUpSvg 
                className={sortField === 'amount' && sortDirection === 'asc' ? 'asc' : ''} 
              />
              <Icons.ChevronDownSvg 
                className={sortField === 'amount' && sortDirection === 'desc' ? 'desc' : ''} 
              />
            </div>
          </th>
          <th>Admin Note</th>
          <th>Certificate</th>
          <th>Invoice</th>
        </tr>
      </thead>
      <tbody>
        {!isLoading && payoutsData && payoutsData.length > 0 ? (
          payoutsData.map((payoutdata, index) => (
            <tr key={payoutdata.id}>
              <td>{index + 1}</td>
              <td className="status-white">{"#" + payoutdata.reference_id}</td>
              <td>{payoutdata.payout_type || 'trading account'}</td>
              <td><div className="td-img"><img className='d-none' src={Img.UPI} alt=""/>{payoutdata.payment_method.name}</div></td>
              <td>{anotherFormatDate(payoutdata.created_at)}</td>
              <td><span className={`td-status td-dot ts-payment ${getStatusColor(payoutdata.payment_status)}`}>{payoutdata.payment_status}</span></td>
              <td>${payoutdata.amount}</td>
              <td className="td-wrap"><div className="td-admin-note">-</div></td>
              <td>
                <div className={`common-btn cb-eg3-fill cwf-am-hover cb-small ${getStatus(payoutdata.payment_status)}`} onClick={() => downloadCertificate(payoutdata.id)}>
                  <span><Icons.AcademicCapSvg />Download</span>
                </div>
              </td>
              <td>
                <div className={`common-btn cb-eg3-fill cwf-am-hover cb-small ${getStatus(payoutdata.payment_status)}`} onClick={() => downloadInvoice(payoutdata.id)}>
                  <span><Icons.ArrowDownTraySvg />Download</span>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            {/* Empty Row or Placeholder if no data */}
          </tr>
        )}
      </tbody>
    </table>
  </div>

  {/* Skeleton Loader (Below the table) */}
  {(isLoading || (payoutsData && payoutsData.length === 0)) && (
    <div className="table-skeletant-bx">
      <div className="skeletant-bx skeletant-design sh-41"></div>
      <div className="skeletant-bx skeletant-design sh-41"></div>
      <div className="skeletant-bx skeletant-design sh-41"></div>
      <div className="skeletant-bx skeletant-design sh-41"></div>
      <div className="skeletant-bx skeletant-design sh-41"></div>
    </div>
  )}




        <div className="empty-box">
          {(payoutsData.length <= 0) &&
            <EmptyBox className="empty-div-bx" eh="Data is not found" esh="Try adjusting filters to get results." />
          }
        </div>

        {(payoutsData && payoutsData.length > 0 && !isLoading) &&
        <Pagination
          currentPage={pagePayoutsData}
          totalPages={totalPagesPayoutsData}
          onPageChange={onChangePagePayoutsData}
          prev={changePrevPagePayoutsData}
          next={changeNextPagePayoutsData}
        />
        }


        {/* <div className="pagenation-filter">
          <div className="pf-left">
            <div className="pf-label">Go to</div>
            <div className="pf-input">
              <input type="number" placeholder="Page" value={goPagePayoutsData} onChange={onChangePagePayoutsData} />
            </div>
          </div>
          <div className="pf-right">
            <div className="pf-pagination">
              <div className="pf-btn" onClick={changePrevPagePayoutsData}>Prev</div>
              <div className="pf-text">
                <div>{pagePayoutsData}</div>
                <span>/</span>
                <div>{totalPagesPayoutsData}</div>
              </div>
              <div className="pf-btn pb-right" onClick={changeNextPagePayoutsData}>Next</div>
            </div>
          </div>
        </div> */}

      </div>
    </div>
  )
}
