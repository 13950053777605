import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import Icons from '../../components/icons';
import * as Img from '../../components/Img';
import Alert from "../../components/Alert";
import Breadcrumb from '../../components/Breadcrumb'
import PayoutHistory from './PayoutHistory'
import SelectInput from '../../components/commonfield/SelectInput';
import CloseIcon from '../../components/CloseIcon';
import PaymentMethodItem from '../PageChallenge/PaymentMethodItem';
import Input from '../../components/commonfield/Input';

export default function PagePayouts() {

  // --- Payout modal start
  const [isPayoutModal, setIsPayoutModal] = useState(false);

  const handlePayoutModalOpen = () => {
    setIsPayoutModal(true);
    handlePayoutTabClick('step1');
    handlePayoutTypeClick('tradingaccount')
  };

  const handlePayoutModalClose = () => {
    setIsPayoutModal(false);
  };
  // --- Payout modal end


  // --- Payout type Tabbing Js Start
  const [ispayoutTab, setIsPayoutTab] = useState('step1');

  const handlePayoutTabClick = (stepId) => {
    setIsPayoutTab(stepId);
  };
  // --- Payout type tabbing Js end


  // --- Payout type Tabbing Js Start
  const [ispayoutType, setIsPayoutType] = useState('tradingaccount');

  const handlePayoutTypeClick = (payoutId) => {
    setIsPayoutType(payoutId);
  };
  // --- Payout type tabbing Js end



  // ---
  const [isAccount, setIsAccount] = useState('Select account');

  const account = [
      { value: 'MT5 1012963', name: 'MT5 1012963' },
      { value: 'MT5 1012962', name: 'MT5 1012962' },
      { value: 'MT5 1012961', name: 'MT5 1012961' },
      { value: 'MT5 1012960', name: 'MT5 1012960' },
      { value: 'MT5 1012959', name: 'MT5 1012959' },
  ];

  const handleAccountChange = (event) => {
      setIsAccount(event.target.value);
  };

  // ---
  const onChageDemo = () => {};

  return (
    <>
      <Breadcrumb className="" breadcrumbIcon="EwalletsSvg" breadcrumbHeading="Payouts" />
      <div className="container-lg cl-custome3">
        <div className="row row-gap-4">
          <div className="col-12">
            <Alert
              className="status-green3 alert-wbtn atb-btn-black alert-fd-column"
              icon="CurrencyDollarSvg"
              heading="Ready to request your payout?"
              text="Please click on the request payout button then proceed to fill out the required information, our team will reach out to you for further advancements."
              btnIcon="Withdraw2Svg"
              linkTo="/payouts-request"
              linkText="Request Payout"
              // btnClick={handlePayoutModalOpen}
              // btnText="Request Payout"
            />
          </div>
          <PayoutHistory />
        </div>
      </div>

      {/* --- Request payout modal start --- */}
      <Modal className="zoom custom-content" centered show={isPayoutModal} onHide={handlePayoutModalClose}>
        
        {/* --- payout step 1 start --- */}
        <div className={`payout-outer ${ispayoutTab === 'step1' ? '' : 'd-none'}`} id="step1">
          <div className="custom-modal-header pb-0 pe-5">
            <CloseIcon onClick={handlePayoutModalClose} />
            <div className='common-box-heading status-aqua2 mb-1'><Icons.CurrencyDollarSvg/>Request a new payout</div>
            <div className='common-box-text'>Use this form to request a new payout, we will verify and get in touch with you for further advancements</div>
          </div>
          <div className="custom-modal-body pb-4">
              
              <div className='common-section-separator'></div>
                          
              <div className="signinup-group">
                <div className="sg-list-bx">
                  <div className={`sg-list-item ${ispayoutType === 'tradingaccount' ? 'active' : ''}`} onClick={() => handlePayoutTypeClick('tradingaccount')}>Trading Account</div>
                  <div className={`sg-list-item ${ispayoutType === 'affiliate' ? 'active' : ''}`} onClick={() => handlePayoutTypeClick('affiliate')}>Affiliate</div>
                </div>
              </div>

              <div  className={`${ispayoutType === 'tradingaccount' ? '' : 'd-none'} `} id="tradingaccount">
                <div className="legal-content mt-4 p-0">
                  <div className="legal-heading">Note:</div>
                  <ul className="ul-dick">
                    <li>Payouts can be requested on monthly basis.</li>
                    <li>ALL TRADES MUST BE CLOSED INCLUDING PENDING ORDERS.</li>
                    <li>Please wait for at least 15 mins after you close your latest trade to request a payout.</li>
                    <li>Minimum amount for an eligible payout is 1% of initial balance.</li>
                    <li>Payouts below $500 will be processed via crypto. Payouts above $500 will be processed via Bank transfer.</li>
                    <li>If Bank transfer does not support the country, the payout will be processed through crypto.</li>
                  </ul>
                </div>

                <div className="signinup-group mt-4">
                  <div className="sg-list-bx">
                    <div className={`sg-list-item active`}>Step 1</div>
                    <div className={`sg-list-item`}>Step 2</div>
                    <div className={`sg-list-item`}>HFT</div>
                  </div>
                </div>

                <div className='col-12'>
                  <SelectInput heading="Choose Account" options={account} selectedValue={isAccount} defaultOption={'Select Account'} value={isAccount} onChange={handleAccountChange} />
                </div>
              </div>

              <div  className={`${ispayoutType === 'affiliate' ? '' : 'd-none'} `} id="affiliate">
                {/* <Alert 
                    className="status-red2"
                    icon="CurrencyDollarSvg"
                    heading="You are not eligible for an affiliate payout yet"
                /> */}
                <Alert 
                    className="status-red2"
                    icon="CurrencyDollarSvg"
                    heading="Minimum amount of 250$ for affiliate withdrawal."
                />
                <div className="affiliate-balance mb-4">
                  <div className="ab-name">Amount</div>
                  <div className="ab-balance">$5000</div>
                </div>
              </div>
              
              <div className="common-submit-btn csb-width-full mb-0" onClick={() => handlePayoutTabClick('step2')}>Continue</div>
          </div>
        </div>
        {/* --- payout step 1 end --- */}

        {/* --- payout step 2 start --- */}
        <div className={`payout-outer ${ispayoutTab === 'step2' ? '' : 'd-none'}`} id="step2">
          <div className="custom-modal-header pt-3 pb-0">
            <div onClick={() => handlePayoutTabClick('step1')} className="common-back-btn cbb-absolute p-0"><Icons.ArrowBackSvg />Back</div>
            <CloseIcon onClick={handlePayoutModalClose} />
            <div className='common-box-heading text-center cbh-16small mb-0'>Choose payment method</div>
          </div>
          <div className="custom-modal-body pb-4">
              
            <div className='common-section-separator'></div>
            <div className="row row-gap-2">
              <PaymentMethodItem src={Img.UPI} alt="UPI" heading="UPI" onClick={() => handlePayoutTabClick('step3')} />
              <PaymentMethodItem src={Img.USDTTRC20} alt="USDT TRC20" heading="USDT TRC20" onClick={() => handlePayoutTabClick('step3')} />
              <PaymentMethodItem src={Img.USDTERC20} alt="USDT ERC20" heading="USDT ERC20" onClick={() => handlePayoutTabClick('step3')} />
              <PaymentMethodItem src={Img.USDTBEP20} alt="USDT BEP20" heading="USDT BEP20" onClick={() => handlePayoutTabClick('step3')} />
              <PaymentMethodItem src={Img.BTC} alt="BTC" heading="BTC" onClick={() => handlePayoutTabClick('step3')} />
            </div>
          </div>
        </div>
        {/* --- payout step 2 end --- */}

        {/* --- payout step 3 start --- */}
        <div className={`payout-outer ${ispayoutTab === 'step3' ? '' : 'd-none'}`} id="step3">
          <div className="custom-modal-header pt-3 pb-0">
            <div className='common-box-heading cbh-16small text-center mb-0'>Fill payout request</div>
            <div onClick={() => handlePayoutTabClick('step2')} className="common-back-btn cbb-absolute p-0"><Icons.ArrowBackSvg />Back</div>
            <CloseIcon onClick={handlePayoutModalClose} />
          </div>
          <div className="custom-modal-body pb-4">
              
            <div className='common-section-separator'></div>

            <div className="row">
              <div className="col-md-6 ">
                <Input heading="Amount" type="number" placeholder="Enter amount" onChange={onChageDemo} />
              </div>

              <div className="col-md-6">
                <Input heading="Withdraw Address" type="text" placeholder="Enter address id" onChange={onChageDemo} />
              </div>

              <div className="col-md-6">
                <Input heading="Account Holder Name" type="text" placeholder="Enter Account Name" onChange={onChageDemo} />
              </div>

              <div className="col-md-6">
                <Input heading="Account Number" type="text" placeholder="Enter Account Number" onChange={onChageDemo} />
              </div>

              <div className="col-md-6">
                <Input heading="IFSC Code" type="text" placeholder="Enter IFSC Code" onChange={onChageDemo} />
              </div>

              <div className="col-md-6">
                <Input heading="Bank Name" type="text" placeholder="Enter Bank Name" onChange={onChageDemo} />
              </div>
            </div>
                          
            <div className="common-submit-btn csb-width-full mb-0" onClick={() => handlePayoutTabClick('step3')}>Submit request</div>
            {/* <div className="lsb-loader-btn csb-width-full mb-0"><img src={Img.loading} alt="" />Please wait ...</div> */}
          </div>
        </div>
        {/* --- payout step 3 end --- */}

      </Modal>
      {/* --- Request payout modal end --- */}
    </>
  )
}
