import React from 'react';

// Context Files
import { ThemeMode } from '../../context/ThemeMode';

// Components 
import Logo from '../../components/Logo';
import Switche from '../../components/commonfield/Switche';

// Icons & Img
import Icons from '../../components/icons';
import loginBg from '../../img/login-bg.png';

export default function LayoutSignInUp({ children }) {

  // --- ---
  const { isDarkMode, toggleTheme } = ThemeMode();
  // --- ---

  return (
    <>
      <div className="pattern-bx pb-full">
          <div className="gradient-background-bx">
              <div className="gb-inner">
                  <div className="gb-shape gb-s-2"></div>
              </div>
          </div>
      </div>

      <section className="signinup-main-box scrollbarHidden">
        <div className="smb-content-box">

          <div className="signinup-header">
            <Logo className="signinup-logo-bx" />
            <div className="cr-theme-btn ctb-sch-btn">
              <div className="cr-tb-lable">{isDarkMode ? "Dark mode" : "Light mode"}</div>
              <Switche
                onClick={toggleTheme} 
                className={isDarkMode ? "active" : ""}
                icon={isDarkMode ?  <Icons.MoonSvg /> : <Icons.SunSvg />}
              />
            </div>
          </div>
          <div className='smb-content-wraper'>
            {children}
          </div>
        </div>
        <img className='smb-side-img' src={loginBg} alt="" />
      </section>
    </>
    
  );
}
