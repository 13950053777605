import React, { useState, useRef, useEffect } from 'react'

// Icons & images common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons'
import EmptyBox from '../../components/EmptyBox';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import DropItem from '../../components/commonfield/DropItem';
import FlashMessage from '../../components/FlashMessage';

export default function BestAccounts() {
  let accountsData = [];
  //Auth related var

  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);

  const { id } = useParams();
  //Loader
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);
  const [addDataIntoArray, setDataIntoArray] = useState();
  const [addsecondDataIntoArray, setSecondDataIntoArray] = useState([]);
  const [isFIlter, setIsFIlter] = useState(0);

  const [activeTab, setActiveTab] = useState(0); // State to manage active tab
  const [selectedOption, setSelectedOption] = useState('All'); // 'All' by default

  // Handling option change to set the correct activeTab and trigger data fetching
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setIsFIlter(1); // Trigger filtering
    if (option === 'All') {
      setActiveTab(0); // Reset to 'All' if the option is 'All'
    } else {
      setActiveTab(Number(option.replace('$', '').replace(',', ''))); // Convert to numerical value
    }
    console.log('Selected Option:', option);
  };

  // Fetch data only when necessary
  const getBestActiveProfitData = async () => {
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL + 'get-best-account', {
        user_id: state.userData.id,
        token: token,
        account_size: activeTab,
        is_filter: isFIlter
      });
      if (response.data.success == '0') {
        setDataIntoArray(response.data.data);
        if (activeTab == 0) {
          setSecondDataIntoArray(response.data.data);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;

      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            const accountProfit = await getBestActiveProfitData();
            setDataLoaded(true);
          } catch (error) {
            console.error('Failed to fetch ib data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);

  useEffect(() => {
    if (isFIlter === 1) {
      getBestActiveProfitData(); // Fetch data with the selected account size filter
    }
  }, [activeTab, isFIlter]); // This effect will trigger only after activeTab or isFIlter changes

  const options = ['All', '$5,000', '$15,000', '$25,000', '$50,000', '$100,000', '$200,000'];

  return (
    <div className="col-12 mt-2">
      <div className="common-box cb-all-none">
        <div className="common-box-heading cbh-small">Best Accounts In Profit</div>

        <div className="leaderboard-filter">
          <DropItem 
            options={options}
            selectedOption={selectedOption}
            onChange={handleOptionChange}
          />
        </div>

        <div className="common-tabs-bx ctb-style-2 d-none">
          <div className={`ctb-item ${activeTab === 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}>
            All
          </div>
          <div className={`ctb-item ${activeTab === 5000 ? 'active' : ''}`} onClick={() => setActiveTab(5000)}>
            $5,000
          </div>
          <div className={`ctb-item ${activeTab === 15000 ? 'active' : ''}`} onClick={() => setActiveTab(15000)}>
            $15,000
          </div>
          <div className={`ctb-item ${activeTab === 25000 ? 'active' : ''}`} onClick={() => setActiveTab(25000)}>
            $25,000
          </div>
          <div className={`ctb-item ${activeTab === 50000 ? 'active' : ''}`} onClick={() => setActiveTab(50000)}>
            $50,000
          </div>
          <div className={`ctb-item ${activeTab === 100000 ? 'active' : ''}`} onClick={() => setActiveTab(100000)}>
            $100,000
          </div>
          <div className={`ctb-item ${activeTab === 200000 ? 'active' : ''}`} onClick={() => setActiveTab(200000)}>
            $200,000
          </div>
        </div>

        <div className="table-responsive custom-table-responsive tr-leaderboard">
          <table className="custom-table off-tr-bg ct-leaderboard ">
            <thead>
              <tr>
                <th className='text-center' style={{width:"70px"}}>Rank</th>
                <th className='text-center' style={{width:"80px"}}>Badge</th>
                <th>User</th>
                <th>Account Size</th>
                <th>Profit</th>
                <th>Profit %</th>
              </tr>
            </thead>
            <tbody>
              {(addDataIntoArray && addDataIntoArray.length > 0) && addDataIntoArray.map((account, index) => (
                <tr key={index}>
                  <td><img className='rank-img' src={index === 0 ? Img.Rank1 : index === 1 ? Img.Rank2 : index === 2 ? Img.Rank3 : index === 3 ? Img.Rank4: index === 4 ? Img.Rank5 : index === 5 ? Img.Rank6 : index === 6 ? Img.Rank7 : index === 7 ? Img.Rank8 : index === 8 ? Img.Rank9 : index === 9 ? Img.Rank10 : Img.Rank1 } alt="" /></td>
                  <td><img className='badge-img' src={addsecondDataIntoArray[0].id === account.id ? Img.Badge1 : Img.Badge3} alt="" /></td>
                  <td>
                    <div className='td-user'>
                      <img className='user-img d-none' src={Img.Avatar1} alt="" />
                      <div>
                        <div className='user-name'>{account.first_name + " " + account.last_name}</div>
                        {account.country && <span className='user-country'>{account.country}</span>}
                      </div>
                    </div>
                  </td>
                  <td>{"$" + account.account_size}</td>
                  <td className="status-green"><Icons.ArrowTrendingUpSvg />{"$" + account.profit}</td>
                  <td className="status-green"><Icons.ArrowTrendingUpSvg />{account.profit_percentage + "%"}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="empty-box">
            {(addDataIntoArray && addDataIntoArray.length <= 0) &&
              <EmptyBox className="empty-div-bx" eh="Data is not found" esh="Try adjusting filters to get results." />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
