import React from 'react'

const Loader = () => {
  return (
    <div className="screen-loader-bx">
        <div className="slb-inner"></div>
    </div>
  )
}

export default Loader