import React, { useState, useEffect } from 'react';
import Icons from './icons';
import AccountStatus from './AccountStatus';

export default function AccountDropItem({onChange = null, options, selected=null}) {
    //const [selected, setSelected] = useState(selected);
    const [isCommonDropActive, setIsCommonDropActive] = useState(false);
    const handleCommonLeave = () => {
        setIsCommonDropActive(false);
    };

    const handleCommonToggle = () => {
        setIsCommonDropActive(!isCommonDropActive);
    }

    const handleOptionChange = (accountId) => {
        onChange(accountId)
        setIsCommonDropActive(false);
    }

    
    useEffect(() => {
        const body = document.body;

        // Add or remove the "overflowhidden" class based on either isActive or isSearchbar state
        if (isCommonDropActive) {
        body.classList.add('isDropActive');
        } else {
        body.classList.remove('isDropActive');
        }

        // Cleanup the class when the component unmounts
        return () => {
        body.classList.remove('isDropActive');
        };

    }, [isCommonDropActive]);

    const formatStatus = (status) => {
        if (!status) return ''; // Handle case where status might be undefined or null
        if(status == 'passed'){
            status = 'Profit target achieved';
          }
        const formattedStatus = status.replace(/_/g, ' '); // Replace underscores with spaces
        return formattedStatus.charAt(0).toUpperCase() + formattedStatus.slice(1); // Capitalize first letter
      };
    return (
        <>
            <div className={`common-drop-item cdi-account ${isCommonDropActive ? 'active' : ''}`} onMouseLeave={handleCommonLeave}>
                {selected >= 0 && options && 
                <div className="cdi-current" onClick={handleCommonToggle}>MT5 {options[selected].account_number}<AccountStatus className={(options[selected].account_status == 'not_passed') ? 'status-red2' : (options[selected].account_status == 'refunded' ? 'status-yellow2' : (options[selected].account_status == 'passed' ? 'status-green2' : (options[selected].account_status == 'disabled' ? 'status-yellow2' : 'status-yellow2')))} statusText={formatStatus(options[selected].account_status)} /><span className="cc-icon"><Icons.DownSvg /></span></div>
                }
                <div className="common-drop-bx">
                    <div className="common-drop-inner">
                        {/* <div className="cdi-item cdi-seleted" onClick={handleOptionChange}>MT5 1012963<AccountStatus className="status-green2" statusText="Passed" /></div> */}
                        {options && options.map((account, index) => (
                        <div key={index} className="cdi-item" onClick={()=>handleOptionChange(index)}>MT5 {account.account_number}<AccountStatus className={(account.account_status == 'not_passed') ? 'status-red2' : (account.account_status == 'refunded' ? 'status-yellow2' : (account.account_status == 'passed' ? 'status-green2' : (account.account_status == 'disabled' ? 'status-yellow2' : 'status-yellow2')))} statusText={formatStatus(account.account_status)} /></div>
                        ))}
                       { /* 
                            <div className="cdi-item" onClick={handleOptionChange}>MT5 1012963<AccountStatus className="status-yellow2" statusText="Disabled" /></div>
                        */} 
                    </div>
                </div>
            </div>
        </>
    );
}
