import React from 'react';

export default function Switche({ className, onClick, icon }) {

    return (
        <div className={`signinup-group-switche ${className}`} onClick={onClick}>
            <div className="sgs-inner">{icon}</div>
        </div>
    );
}
