import React from 'react';
import { Link } from 'react-router-dom';
import Icons from './icons';

export default function Alert({ className = "", icon, heading, subHeading, text, btnIcon, btnText, btnClick, linkTo, linkTarget="_self", linkText }) {
  const IconComponent = Icons[icon] || null;
  const BtnIconComponent = Icons[btnIcon] || null;

  return (
    <div className={`alert-text-bx ${className}`}>
      <div className="atb-inner">
        {heading && (
          <div className="atb-heading">{IconComponent && <div className="atb-icon"><IconComponent /></div>} <div>{heading}{subHeading && <span>{subHeading}</span>}</div></div>
        )}
        {text && <div className="atb-text">{text}</div>}
      </div>
      {btnText && <div className="atb-btn" onClick={btnClick}>{BtnIconComponent && <BtnIconComponent />}{btnText}</div>}
      {linkText && <Link to={linkTo} target={linkTarget} className="atb-btn">{BtnIconComponent && <BtnIconComponent />}{linkText}</Link>}
    </div>
  );
}
