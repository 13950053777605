import React from 'react';

export default function PredictionItem({ time, child }) {
  return (
    <tr>
      <td className="td-ctp-time">
        <div className="ctp-time">{time}</div>
        <div className="ctp-line"></div>
      </td>
      <td>
        <div className="ctp-item-bx">
          {child}
        </div>
      </td>
    </tr>
  );
}
